import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Accordian,
  AccordionDetails,
  AccordionSummaryMobile,
  EditContainer,
  HeaderTextMobile,
  HeaderWrapper,
  MasterWrapper,
  SaveContainer,
} from "./RightBarAccordian.style";
import { useDispatch, useSelector } from "react-redux";
import { notesSnackDropdownActions } from "../../../redux/reducers/notesSnackDropdown";
import MobileListIcon from "./icons/mobileListicon";
import { Box } from "@mui/material";
import SaveIcon from "./icons/saveIcon";
import MobileModal from "../../mobileModal/mobileModal";
import api from "../../../Services";
import { useSnackbar } from "notistack";
import IconButtonComp from "../IconButton/IconButton";
import Edit from "../../Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/icons/Edit";
import DeleteSvg from "../../Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/icons/DeleteSvg";
import { myLibraryActions } from "../../../redux/reducers/myLibrary";
import Menu from "../../UI/Menu/Menu";
import ThreeDotsSvg from "../../Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/icons/threedotsSvg";
const iconOptions = [
  {
    label: "Edit",
    icon: <Edit color="#ffff" />,
    name: "edit",
  },
  {
    label: "Delete",
    icon: <DeleteSvg color="#ffff" height={16} width={16} />,
    name: "delete",
  },
];
const RightBarAccordian = ({ controller, isOctiLibrary }) => {
  const [openModal, setOpenModal] = useState(false);
  const [dataToUpdate, setDataToUpdat] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedTag, setExpandedTag] = React.useState(null);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.notesSnack);
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = React.useState(false);
  const [expandedData, setExpandedData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const changeAccordionHandler = (
    content,
    index,
    tagId,
    snackId,
    chapterId
  ) => {
    return (event, isExpanded) => {
      if (expanded === index) {
        dispatch(
          notesSnackDropdownActions.setDataforSnack({
            tagId: null,
            expanded: false,
            index: null,
            snackId: null,
            chapterId: null,
          })
        );
        setExpandedTag(null);
        return setExpanded(false);
      }

      dispatch(
        notesSnackDropdownActions.setDataforSnack({
          tagId: tagId,
          expanded: true,
          index: index,
          snackId,
          chapterId: chapterId,
        })
      );
      setExpandedData(content);
      setExpandedTag(tagId);
      setExpanded(index);
    };
  };

  useEffect(() => {
    if (data.expanded) {
      controller.map((snack, index) => {
        let snackId = snack?.snackId
          ? snack.snackId
          : snack?.content?.props?.data?.snackId;
        let chapterId = snack?.chapterId
          ? snack.chapterId
          : snack?.content?.props?.data?.chapterId;

        if (
          snack.tagId == data.tagId &&
          index == data.index &&
          data.snackId == snackId &&
          data.chapterId == chapterId
        ) {
          setExpandedData(snack?.content?.props);
          setExpanded(data.index);
          setExpandedTag(data.tagId);
        }
      });
    }
  }, []);

  const closeModal = () => {
    setOpenModal(false);
  };

  const addNoteToLibraryApiCall = async (payload) => {
    let token = localStorage.getItem("auth");
    let temp = api.addNoteToLibrary(payload, token);
    return temp;
  };
  const handleResponseOfLibraryPush = (res) => {
    if (res.ok) {
      enqueueSnackbar("Content successfully copied to My Library.", {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else
      enqueueSnackbar(`Failed to add to library!`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    return false;
  };
  const pushTagToLibrary = () => {
    if (dataToUpdate) {
      const { chapterId, snackId, tagId } = dataToUpdate;
      addNoteToLibraryApiCall({ chapterId, snackId, tagId })
        .then((el) => {
          handleResponseOfLibraryPush(el);
          setDataToUpdat(null);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleMode = (userChoice) => {
    dispatch(myLibraryActions.findNotesIdStat(expandedData?.id));
    if (userChoice.name === "edit") {
      setEditMode(true);
      setIsMenuOpen(false);
    }
    if (userChoice.name === "delete") {
      expandedData?.deleteSingleNote(expandedData);
      setIsMenuOpen(false);
    }
  };

  return (
    <MasterWrapper>
      {controller.length > 0 &&
        controller.map((accordionData, index) => (
          <Box sx={{ position: "relative" }}>
            {isOctiLibrary ? (
              <></>
              // <SaveContainer
              //   expanded={expanded === index}
              //   onClick={() => {
              //     setDataToUpdat({
              //       tagId: accordionData?.tagId,
              //       chapterId: accordionData?.content?.props?.chapterId,
              //       snackId: accordionData?.content?.props?.snackId,
              //     });
              //     setOpenModal(true);
              //   }}
              // >
              //   <SaveIcon />
              // </SaveContainer>
            ) : (
              <EditContainer expanded={expanded === index}>
                <Menu
                  setIsMenuOpen={setIsMenuOpen}
                  sideEffects={handleMode}
                  listItems={iconOptions}
                  name={accordionData?.tagName}
                >
                  <IconButtonComp isMenuOpen={isMenuOpen} show={true}>
                    <ThreeDotsSvg hideFill={true} />
                  </IconButtonComp>
                </Menu>
              </EditContainer>
            )}
            <Accordian
              expanded={expanded === index}
              onChange={changeAccordionHandler(
                accordionData.content?.props,
                index,
                accordionData.tagId,
                accordionData?.snackId
                  ? accordionData.snackId
                  : accordionData?.content?.props?.data?.snackId,
                accordionData?.chapterId
                  ? accordionData.chapterId
                  : accordionData?.content?.props?.data?.chapterId
              )}
              disableGutters
              key={index}
            >
              <AccordionSummaryMobile
                aria-controls="panel1d-content"
                id="panel1d-header"
                expanded={expanded === index}
                isOctiLibrary={isOctiLibrary}
              >
                <HeaderWrapper>
                  <MobileListIcon isactive={false} />
                  <HeaderTextMobile expanded={expanded === index}>
                    {accordionData.title.toUpperCase()}
                  </HeaderTextMobile>
                </HeaderWrapper>
              </AccordionSummaryMobile>

              <AccordionDetails>
                {React.cloneElement(accordionData.content, {
                  expanded: expandedTag,
                  editMode: editMode,
                  setEditMode: setEditMode,
                })}
              </AccordionDetails>
            </Accordian>
          </Box>
        ))}
      <MobileModal
        open={openModal}
        modaltext={"Are you sure you want to save it to my library?"}
        close={closeModal}
        reset={() => {}}
        succes={pushTagToLibrary}
        isCopy={true}
        isDelete={false}
        warningtext={" "}
        cancelText={"No"}
      />
    </MasterWrapper>
  );
};

RightBarAccordian.propTypes = {
  controller: PropTypes.shape({
    map: PropTypes.func,
  }),
};

RightBarAccordian.defaultProps = {
  controller: [{ title: "Title", content: <div>CONTENT</div> }],
};

export default RightBarAccordian;
