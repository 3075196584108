import { Box, Typography, styled } from "@mui/material";
import TypographyCompo from "./../Typography/TypographyCompo";
import pallete from "./../../../config/palette";

export const MobileFlashHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "20px",
}));
export const MobileTypographyStats = styled(TypographyCompo)(({ theme }) => ({
  color: "#767A84",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "148%",
  background: "rgba(173, 180, 197, 0.20)",
  padding: theme.spacing(0.5, 2, 0.5, 2),
  borderRadius: "8px 0px 0px 8px",
}));

export const MobileTypographyStatsCompleted = styled(TypographyCompo)(
  ({ theme }) => ({
    background: "rgba(229, 99, 189, 0.30)",
    color: "#E015A2",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "148%",
    padding: theme.spacing(0.5, 2, 0.5, 2),
    borderRadius: "8px 0px 0px 8px",
  })
);
export const MobileTypographyStatsdone = styled(TypographyCompo)(
  ({ theme }) => ({
    background: "rgba(129, 208, 212, 0.30)",
    color: "#2A7A7E",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "148%",
    padding: theme.spacing(0.5, 2, 0.5, 2),
    borderRadius: "8px 0px 0px 8px",
  })
);

export const MobileTypographyName = styled(TypographyCompo)(({ theme }) => ({
  color: "rgba(17, 38, 60, 0.70)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
export const TopicTitleMobile = styled(TypographyCompo)(({ theme }) => ({
  color: "#251038",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
}));

export const TypographyStarted = styled(TypographyCompo)(({ theme }) => ({
  background: "#edf5f8",
  color: "#8bd3d8",
  padding: theme.spacing(0.5, 2, 0.5, 2),
  borderRadius: "0px 10px 10px 0px",
  marginRight: theme.spacing(3),
}));
export const TypographyStats = styled(TypographyCompo)(({ theme }) => ({
  background: "#d3cfd7",
  color: "#251038",
  padding: theme.spacing(0.5, 2, 0.5, 2),
  borderRadius: "0px 10px 10px 0px",
  marginRight: theme.spacing(3),
}));
export const TypographyCompleted = styled(TypographyCompo)(({ theme }) => ({
  background: "#f9d0ec",
  color: "#e015a2",
  padding: theme.spacing(0.5, 2, 0.5, 2),
  borderRadius: "0px 10px 10px 0px",
  marginRight: theme.spacing(3),
}));
export const DivText = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: pallete.unselected,
  paddingLeft: "10px",
}));
export const DivHeading = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  paddingDown: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "13.625rem",
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(2),
  },
}));
export const IconTextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "#251038",
}));
export const ImageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));
export const TopicTitle = styled(TypographyCompo)(({ theme }) => ({
  fontWeight: "900",
}));
export const ImageDic = styled("div")(({ theme }) => ({
  height: "18.3rem",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
}));

export const IconTextWrapperMobile = styled("div")(({ theme, isStar }) => ({
  display: "flex",
  alignItems: "center",
  color: "#251038",
  gap: "10px",
}));

export const IconText = styled(Typography)(({ theme, show }) => ({
  color: show ? "#624ba2" : "#11263C",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Satoshi",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "180%",
}));

export const IconTextTotal = styled(Typography)(({ theme, show }) => ({
  marginRight: "2px",
  color: "#624ba2",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Satoshi",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 900,
  lineHeight: "180%",
}));
