import React from "react";
import Flashcard from "./../../../../../../UI/FlashCard/FlashCard";
import api from "../../../../../../../Services";
import createResponses from "../../../../../../../helpers/createResponses";
import { useSnackbar } from "notistack";
import Model from "../../../../../../UI/Modal/Modal";
import FlashcardDeleteModel from "../../../MyLibrary/Flashcards/MyLibFlashCardView/FlashcardDeleteModel/FlashcardDeleteModel";

const { createLoading, initialLoading } = createResponses;

const EachFlashcardHandler = (props) => {
  const [loading, setLoading] = React.useState(initialLoading);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = React.useState(false);

  const [saved, setSaved] = React.useState(props.isInLibrary);

  const saveHandler = (e, snackId, snackTagId) => {
    if (e) {
      prepareToSaveToLibrary(snackId, snackTagId);
    }

    if (!e) {
      setLoading(createLoading(false, "save"));
    }
  };

  const prepareToSaveToLibrary = (snackId, tagId) => {
    const courseInfo = props.questionsArr;

    let payload = {};
    if (props.type === "snack") {
      payload = {
        chapterId: courseInfo[0].chapterId,
        snackId: props.index,
        tagId: tagId && tagId,
      };
    }

    if (props.type === "chapter") {
      payload = {
        chapterId: props.index,
        snackId: snackId && snackId,
        tagId: props.activeTag?.id,
      };
    }

    if (props?.type === "unit") {
      payload = {
        chapterId: props.snackId,
        tagId: props.activeTag?.id,
      };
    }

    saveController(payload);
  };

  const fetchFlashcardsFromApi = async () => {
    const token = localStorage.getItem("auth");
    let temp = api.fetchFlashcardsFromLibrary(token);
    return temp;
  };

  const getFlashcardsHandler = (payload) => {
    fetchFlashcardsFromApi()
      .then((el) => {
        if (el.data[0].stackId) {
          props.handleSetStackId(el.data[0].stackId);
        }
        return;
      })
      .catch((err) => console.error(err));
  };

  const sendSaveToAPI = (payload) => {
    let token = localStorage.getItem("auth");

    let temp = api.addFlashcardToLibrary(payload, token);

    return temp;
  };

  const handleSuccess = (res, payload) => {
    setSaved(true);
    setLoading(createLoading(false, "true"));
    getFlashcardsHandler(payload);
    return true;
  };

  const handleFail = (res) => {
    setLoading(createLoading(false, "true"));
    return true;
  };

  const saveController = (payload) => {
    sendSaveToAPI(payload)
      .then((el) => {
        if (!el.error) handleSuccess(el, payload);
        else handleFail(el);
      })
      .catch((error) => {
        handleFail(error);
      });
  };

  const deleteFlashcardStackApiCall = async (fcStackId) => {
    let token = localStorage.getItem("auth");
    let temp = api.deleteFlashcardStack(token, fcStackId);
    return temp;
  };

  const deleteFlashcardStackHandler = () => {
    setLoading(createLoading(true, "save"));

    deleteFlashcardStackApiCall(props.stackId)
      .then((el) => {
        if (!el.error) {
          setSaved(false);
          setLoading(createLoading(false, "save"));
        } else {
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Flashcard
        deleteFlashcardStack={deleteFlashcardStackHandler}
        flashcardStatus={props.flashcardStatus}
        handleOpenModal={handleOpenModal}
        show={saved}
        loading={loading.value}
        setShow={saveHandler}
        prepareToSaveToLibrary={prepareToSaveToLibrary}
        {...props}
      />

      <Model open={openModal} setOpen={handleOpenModal}>
        <FlashcardDeleteModel
          deleteFlashcardStack={deleteFlashcardStackHandler}
          setOpen={handleOpenModal}
          setShow={(e) => {}}
        />
      </Model>
    </>
  );
};

export default EachFlashcardHandler;
