import React, { useState, useReducer, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import {
  CardDivWrapper,
  DividerWrapper,
  Container,
  SetperDiv,
  ButtonTextWrapper,
  StyleButton,
  RightLeftButtonWrapper,
  Typography,
  ButtonDefinition,
  ButtonTerm,
  ButtonDefinitionWrapper,
  IconButtonWrapper,
  ButtonTermMobile,
  ButtonDefinitionMobile,
  IconButtonWrapperMobile,
  StepperWrapper,
  FlashIconWrapper,
  DiduKnowBtn,
} from './FlashCardView.style.js';
import Pallete from './../../../config/palette.js';
import MobileStepper from '@mui/material/MobileStepper';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import '../../../App.css';
import StackNumbers from './StackNumbers';
import TypographyCompo from './../Typography/TypographyCompo';
import IconButton from '@mui/material/IconButton';
import OpenFullView from './icons/OpenFullView';
import ClickSvg from './icons/ClickSvg';
import BackSvg from './icons/BackSvg';
import NextSvg from './icons/NextSvg';
import CrossSvg from './icons/CrossSvg';
import QuestionMarkSvg from './icons/QuestionMarkSvg';
import CheckSvg from './icons/CheckSvg';
import PropTypes from 'prop-types';
import TextEditorRenderOutput from './../TextEditorRenderOutput/TextEditorRenderOutput';
import Alert from './../Alert/Alert';
import { useSnackbar } from 'notistack';
import Loader from '../Loader/Loader.jsx';
import MobileDetector from '../../../helpers/mobileDetector.jsx';
import { Box, Stack } from '@mui/material';
import { formatText } from '../List/Utils.js';
import NewBackIcon from './icons/newBackIcon.jsx';
import NewNextIcon from './icons/newNextIcon.jsx';

const controller = [
  {
    term: 'A dye that changes to different colors in acids and alkalis.',
    definition: 'Indicators are substances whose solutions.',
  },
];

const FlashCardView = ({
  controllerData,
  sideEffect,
  awaitingUserResponse,
  flashCardIdSideEffect,
  getMenuState,
  loading,
  doneFlashCards,
  isMyLibrary,
}) => {
  const isMobile = MobileDetector();
  const handle = useFullScreenHandle();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [activeFlash, setActiveFlash] = useState(true);
  const [_fullView, set_FullView] = useState(true);
  const [isSelected, setIsSelected] = useState({ value: false });
  const [filteredData, setFilteredData] = useState([]);
  const [allFilteredData, setAllFilteredData] = useState({
    yes: [],
    no: [],
    maybe: [],
    remaining: [],
  });

  const [activeTag, setActiveTag] = useState('REMAINING');
  const [allCards, setAllCards] = useState([]);

  const findCorrespondingQuestions = (userResponses, cards) => {
    const data = cards;
    data.forEach((card) => {
      const response = userResponses.find((res) => {
        return isMyLibrary ? res.fcId === card.fcId : res.fcId === card.id;
      });
      if (response) {
        card.response = response.response;
      } else {
        card.response = 'REMAINING';
      }
    });

    return data;
  };

  const seperateAllCards = () => {
    const cardsWithMaybeResult = allCards.filter(
      (data) => data.response === 'MAYBE'
    );
    const cardsWithYesResult = allCards.filter(
      (data) => data.response === 'YES'
    );
    const cardsWithNo = allCards.filter((data) => data.response === 'NO');
    const cardsWithRemainig = allCards.filter(
      (data) => data.response === 'REMAINING'
    );
    setFilteredData(cardsWithRemainig);
    setAllFilteredData((prevState) => ({
      ...prevState,
      yes: cardsWithYesResult,
      no: cardsWithNo,
      maybe: cardsWithMaybeResult,
      remaining: cardsWithRemainig,
    }));
  };

  const handleAnsClick = (option, data) => {
    const { id, response } = data;

    const filteredCardsWithYes = allFilteredData.yes.filter(
      (card) => card.id !== id
    );
    const filteredCardsWithNo = allFilteredData.no.filter(
      (card) => card.id !== id
    );
    const filteredCardsWithMaybe = allFilteredData.maybe.filter(
      (card) => card.id !== id
    );
    const filteredCardsWithRemaining = allFilteredData.remaining.filter(
      (card) => card.id !== id
    );

    if (option === 'YES') {
      if (data) {
        data.response = 'YES';

        setAllFilteredData((prevState) => {
          return {
            ...prevState,
            yes: [...filteredCardsWithYes, data],
            no: filteredCardsWithNo,
            maybe: filteredCardsWithMaybe,
            remaining: filteredCardsWithRemaining,
          };
        });
      }
    } else if (option === 'NO') {
      if (data) {
        data.response = 'NO';

        setAllFilteredData((prevState) => ({
          ...prevState,
          no: [...filteredCardsWithNo, data],
          yes: filteredCardsWithYes,
          maybe: filteredCardsWithMaybe,
          remaining: filteredCardsWithRemaining,
        }));
      }
    } else if (option === 'MAYBE') {
      if (data) {
        data.response = 'MAYBE';

        setAllFilteredData((prevState) => ({
          ...prevState,
          maybe: [...filteredCardsWithMaybe, data],
          yes: filteredCardsWithYes,
          no: filteredCardsWithNo,
          remaining: filteredCardsWithRemaining,
        }));
      }
    }
  };

  useEffect(() => {
    seperateAllCards();
  }, [allCards]);
  useEffect(() => {
    const result = findCorrespondingQuestions(doneFlashCards, controllerData);
    setFilteredData(result);
    setAllCards(result);
  }, [controllerData]);

  const handleFilter = (option) => {
    switch (option.toUpperCase()) {
      case 'YES':
        setFilteredData(allFilteredData.yes);
        setActiveTag('KNOWN WELL');
        break;
      case 'NO':
        setFilteredData(allFilteredData.no);
        setActiveTag('NOT LEARNED');
        break;
      case 'MAYBE':
        setFilteredData(allFilteredData.maybe);
        setActiveTag('NOT SURE');
        break;
      case 'REMAINING':
        setFilteredData(allFilteredData.remaining);
        setActiveTag('REMAINING');
        break;
      default:
        break;
    }
    setActiveStep(0);
  };

  useEffect(() => {
    if (filteredData && activeStep < filteredData.length) {
      setActiveFlash(true);
    }
  }, [filteredData, activeStep]);
  const handleChangeSelected = (value) => {
    setIsSelected((prevState) => ({ ...prevState, value }));
  };

  const maxSteps = filteredData && filteredData.length;

  const initialState = {
    allAttemptedQuestion: [],
    totalScore: { no: 0, yes: 0, mayBe: 0 },
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'YES':
        sideEffect({ response: 'YES', id: filteredData[activeStep].id });
        return {
          ...state,
          allAttemptedQuestion: action.payload.attemptedQuestions,
          totalScore: action.payload.totalScore,
        };

      case 'NO':
        sideEffect({ response: 'NO', id: filteredData[activeStep].id });
        return {
          ...state,
          allAttemptedQuestion: action.payload.attemptedQuestions,
          totalScore: action.payload.totalScore,
        };

      case 'MAYBE':
        sideEffect({ response: 'MAYBE', id: filteredData[activeStep].id });
        return {
          ...state,
          allAttemptedQuestion: action.payload.attemptedQuestions,
          totalScore: action.payload.totalScore,
        };

      case 'REMAINING':
        return {
          allAttemptedQuestion: [],
          totalScore: { no: 0, yes: 0, mayBe: 0 },
        };

      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  function makePayLoad(type) {
    let obj = {};
    if (type === 'no') {
      obj = { no: 1, yes: 0, mayBe: 0 };
    } else if (type === 'yes') {
      obj = { yes: 1, no: 0, mayBe: 0 };
    } else {
      obj = { mayBe: 1, no: 0, yes: 0 };
    }
    let temp = [];
    if (state.allAttemptedQuestion.length !== 0) {
      temp = [...state.allAttemptedQuestion];
    }

    temp[activeStep] = obj;

    const score = temp.reduce(
      (acc = {}, item = {}) => {
        acc.no = acc.no + item.no;
        acc.yes = acc.yes + item.yes;
        acc.mayBe = acc.mayBe + item.mayBe;

        return acc;
      },
      {
        no: 0,
        yes: 0,
        mayBe: 0,
      }
    );

    return {
      attemptedQuestions: temp,
      totalScore: score,
    };
  }
  flashCardIdSideEffect(controllerData[activeStep].id);

  const disabledRightArrow = (userHasSelected) => {
    const shouldBeDisabled = awaitingUserResponse.loading;

    return shouldBeDisabled;
  };

  let check;

  if (activeTag === 'NOT SURE') {
    check = allFilteredData?.maybe?.filter((item) => {
      return item?.id === filteredData[activeStep]?.id;
    });
  } else if (activeTag === 'KNOWN WELL') {
    check = allFilteredData?.yes?.filter((item) => {
      return item?.id === filteredData[activeStep]?.id;
    });
  } else if (activeTag === 'NOT LEARNED') {
    check = allFilteredData?.no?.filter((item) => {
      return item?.id === filteredData[activeStep]?.id;
    });
  } else {
    check = [];
  }

  useEffect(() => {
    if (
      activeTag === 'NOT LEARNED' ||
      activeTag === 'KNOWN WELL' ||
      activeTag === 'NOT SURE'
    ) {
      dispatch({
        type: 'REMAINING',
      });
    }
  }, [activeTag]);

  return (
    <Container isMyLibrary={isMyLibrary}>
      <StackNumbers
        activeTag={activeTag}
        handleFilter={handleFilter}
        remaining={allFilteredData.remaining.length}
        notLearned={allFilteredData.no.length}
        knownWell={allFilteredData.yes.length}
        notSure={allFilteredData.maybe.length}
      />

      <FullScreen handle={handle} className="fullscreen-enabled">
        <CardDivWrapper>
          <SetperDiv>
            <Stack direction={'row'} spacing={2}>
              <ButtonTermMobile size="small">
                {activeFlash ? 'Front' : 'Back'}
              </ButtonTermMobile>
              <IconButtonWrapperMobile
                _fullview={_fullView}
                onClick={() => {
                  _fullView ? handle.enter() : handle.exit();
                  set_FullView(!_fullView);
                }}
              >
                <OpenFullView isMobile={false} open={_fullView} />
              </IconButtonWrapperMobile>
            </Stack>
          </SetperDiv>

          {!filteredData || filteredData.length === 0 ? (
            <Alert
              message={'No flashcard found for this section'}
              severity="warning"
            />
          ) : (
            <>
              <Typography
                sx={{
                  color: 'var(--black, #11263C)',
                  textAlign: 'center',
                  fontFamily: 'Satoshi',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '28px',
                }}
              >
                <TextEditorRenderOutput
                  parentStyles={{
                    fontSize: '14px',
                    textAlign: activeFlash ? 'center' : 'left',
                    fontFamily: 'Inter',
                  }}
                  data={
                    activeFlash
                      ? filteredData[activeStep]?.question
                      : filteredData[activeStep]?.answer
                  }
                  isFlashCard={true}
                />
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Divider
                  sx={{ width: '40%', background: '#ADB4C5', opacity: '0.6' }}
                />
              </Box>
              <ButtonDefinitionWrapper>
                <ButtonDefinition
                  variant="text"
                  onClick={() => setActiveFlash(!activeFlash)}
                  startIcon={<ClickSvg />}
                >
                  {activeFlash
                    ? 'Click to see the back side'
                    : 'Click to see the front side'}
                </ButtonDefinition>
                <ButtonDefinitionMobile
                  variant="text"
                  onClick={() => setActiveFlash(!activeFlash)}
                  startIcon={<ClickSvg />}
                >
                  {activeFlash
                    ? 'Click to see the back side'
                    : 'Click to see the front side'}
                </ButtonDefinitionMobile>
              </ButtonDefinitionWrapper>
            </>
          )}
        </CardDivWrapper>
        {/* Flash Cards Yes Or No OR Maybe Section */}
        <ButtonTextWrapper>
          <DiduKnowBtn>Did you know that? </DiduKnowBtn>

          <RightLeftButtonWrapper>
            <StyleButton
              disabled={state.allAttemptedQuestion[activeStep]?.no === 1}
              size="small"
              background={
                (
                  check?.length > 0
                    ? check[0]?.response === 'NO'
                    : state.allAttemptedQuestion[activeStep]?.no === 1
                )
                  ? '#E015A2'
                  : '#f6e2f3'
              }
              sx={{ color: { xs: '#E015A2', sm: '#e229ab' } }}
              onClick={() => {
                if (filteredData.length >= 1) {
                  handleChangeSelected(true);
                  dispatch({ type: 'NO', payload: makePayLoad('no') });

                  handleAnsClick('NO', filteredData[activeStep]);
                }
              }}
            >
              {(
                check?.length > 0
                  ? check[0]?.response === 'NO'
                  : state.allAttemptedQuestion[activeStep]?.no === 1
              ) ? (
                <CrossSvg />
              ) : (
                'No'
              )}
            </StyleButton>

            <StyleButton
              size="small"
              disabled={state.allAttemptedQuestion[activeStep]?.mayBe === 1}
              background={
                (
                  check?.length > 0
                    ? check[0]?.response === 'MAYBE'
                    : state.allAttemptedQuestion[activeStep]?.mayBe === 1
                )
                  ? '#D68504'
                  : '#f9eee0'
              }
              sx={{ color: '#D68504' }}
              onClick={() => {
                if (filteredData.length >= 1) {
                  handleChangeSelected(true);
                  dispatch({
                    type: 'MAYBE',
                    payload: makePayLoad('mayBe'),
                  });
                  handleAnsClick('MAYBE', filteredData[activeStep]);
                }
              }}
            >
              {(
                check?.length > 0
                  ? check[0]?.response === 'MAYBE'
                  : state.allAttemptedQuestion[activeStep]?.mayBe === 1
              ) ? (
                <QuestionMarkSvg />
              ) : (
                'Maybe'
              )}
            </StyleButton>
            <StyleButton
              size="small"
              disabled={state.allAttemptedQuestion[activeStep]?.yes === 1}
              background={
                (
                  check?.length > 0
                    ? check[0]?.response === 'YES'
                    : state.allAttemptedQuestion[activeStep]?.yes === 1
                )
                  ? '#2A7A7E'
                  : '#edf5f8'
              }
              sx={{ color: '#2A7A7E' }}
              onClick={() => {
                if (filteredData.length >= 1) {
                  handleChangeSelected(true);
                  dispatch({
                    type: 'YES',
                    payload: makePayLoad('yes'),
                  });
                  handleAnsClick('YES', filteredData[activeStep]);
                }
              }}
            >
              {(
                check?.length > 0
                  ? check[0]?.response === 'YES'
                  : state.allAttemptedQuestion[activeStep]?.yes === 1
              ) ? (
                <CheckSvg />
              ) : (
                'Yes'
              )}
            </StyleButton>
          </RightLeftButtonWrapper>
        </ButtonTextWrapper>
        {/* Pagination Section  */}
        <FlashIconWrapper>
          <IconButton
            onClick={() => {
              if (filteredData.length >= 1) {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
                setActiveFlash(false);
              }
            }}
            disabled={activeStep === 0}
            aria-label="OpenvIEW"
            sx={{
              width: '35.5px',
              height: '35.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '2px solid #624BA2',
              borderRadius: '100%',
            }}
          >
            <NewBackIcon />
          </IconButton>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              background: 'transparent',
              color: '#624BA2',
              fontSize: '13px',
              fontFamily: 'Satoshi',
              fontWeight: 700,
            }}
          ></MobileStepper>
          <IconButton
            onClick={() => {
              if (filteredData.length >= 1) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setActiveFlash(false);

                if (activeStep !== maxSteps - 1) {
                  if (state.allAttemptedQuestion[activeStep]) {
                    setActiveFlash(true);
                  }
                } else {
                  getMenuState(true);
                }
              }
            }}
            sx={{
              width: '35.5px',
              height: '35.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '2px solid #624BA2',
              borderRadius: '100%',
            }}
            aria-label="OpenvIEW"
          >
            <NewNextIcon />{' '}
          </IconButton>
        </FlashIconWrapper>
      </FullScreen>
    </Container>
  );
};

FlashCardView.propTypes = {
  awaitingUserResponse: PropTypes.shape({
    loading: PropTypes.any,
  }),
  controllerData: PropTypes.shape({
    length: PropTypes.number,
  }),
  flashCardIdSideEffect: PropTypes.func,
  getMenuState: PropTypes.func,
  sideEffect: PropTypes.func,
};
FlashCardView.defaultProps = {
  controllerData: controller,
  sideEffect: (e) => {
    return;
  },
  flashCardIdSideEffect: (e) => {
    return;
  },
  getMenuState: (e) => {
    return;
  },
  awaitingUserResponse: {
    loading: false,
  },
};

export default FlashCardView;
