import React from "react";

const DividerSideBarSvg = () => {
  return (
    <svg
      width="65"
      height="1"
      viewBox="0 0 65 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line opacity="0.2" y1="0.5" x2="65" y2="0.5" stroke="#B2B2B2" />
    </svg>
  );
};

export default DividerSideBarSvg;
