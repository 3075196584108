import { Stack, Typography, styled } from "@mui/material";

export const MobileWrapper = styled("div")(({ theme }) => ({
  borderRadius: "10px",
  background: "transparent",
  width: "100%",
  boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.06)",
  display: "block",
}));

export const PrimaryNameMobile = styled(Typography)(({ theme }) => ({
  color: "var(--black, #11263C)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

export const MobileDivider = styled("span")(() => ({
  borderRight: "1px solid var(--black, #11263C)",
  margin: "0 4px",
  color: "var(--black, #11263C)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
}));

export const SecondaryNameMobile = styled(Typography)(({ theme }) => ({
  color: "var(--black, #11263C)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

export const MoveToLibraryButtonMobile = styled("div")(
  ({ theme, isCopied }) => ({
    display: "flex",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "100px",
    border: "1px solid #E015A2",
    background: isCopied ? "#624BA2" : "#E015A2",
    cursor: "pointer",
  })
);

export const NotesContentBox = styled("div")(({ theme }) => ({
  margin: "0px",
  padding: "0px",
  background: "#FFFF",
  borderBottomRightRadius: "10px",
  borderBottomLeftRadius: "10px",
}));
