import React, { useState,useEffect } from 'react';
import {
  ListSubheader,
  ListItemText,
  Divider,
  Collapse,
  Stack,
} from '@mui/material';
import defaultProps from './defaultProps.js';
import {
  StyledItemMobile,
  StyledListContainerMobile,
} from './List.style';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { notesDropdownActions } from '../../../redux/reducers/notesDropdown.js';
import AccordianMobileSvg from './icons/AccordianMobileSvg.jsx';
import MobileDetector from '../../../helpers/mobileDetector.jsx';
import MobileListIcon from '../RightBarAccordian/icons/mobileListicon.jsx';
import { Mixpanel } from '../../../helpers/Mixpanel.js';

const ListItemComp = ({
  structure,
  setStructure,
  item,
  nested,
  name,
  label,
  icon,
  childrenElements,
  listItemClickEvent,
  secondary,
  layer,
  type,
  expandAll,
  isFlashcard,
  isLibrary,
  active,
  handleOpenMobileDropDown,
  isAssesment,
  selection,
  selectedData,
  activeScreen,
  setActiveScreen,
  setSelectedData,
  isNotes,
  ...props
}) => {
  const dispatch = useDispatch();
  const IsMobile = MobileDetector();
  const [selected, setSelected] = useState(null);
  const forceOpenState = (state) =>{};
  const [padding, setPadding] = React.useState('0');
  const [selectedItem,setSelectedItem] = useState()
  const [openState, setOpenState] = useState(item.isExpanded);


  useEffect(()=>{
    let previousData;
    if(selectedItem){
      
      switch (item.type) {
        case "unit":
          previousData = structure?.filter((unit) => {
            return unit.id === item.id;
          });
          setOpenState(
            previousData?.length > 0 ? previousData[0].isExpanded : undefined
          );
          break;

        case "chapter":
          // if unit level exists
          if (item.unitId) {
            previousData = structure
              ?.filter((unit) => {
                return unit.id === item.unitId;
              })[0]
              ?.childrenElements?.filter((element) => {
                return element.id === item.id;
              });
          } else {
            previousData = structure?.filter((chapter) => {
              return chapter.id === item.id;
            });
          }
          setOpenState(
            previousData?.length > 0 ? previousData[0].isExpanded : undefined
          );
          break;

        case "snack":
          previousData = structure
            ?.filter((unit) => {
              return unit.id === item.unitId;
            })[0]
            ?.childrenElements?.filter((element) => {
              return element.id === item.chapterId;
            })[0]
            ?.childrenElements?.filter((snackElement) => {
              return snackElement.id === item.id;
            });

          setOpenState(
            previousData?.length > 0 ? previousData[0].isExpanded : undefined
          );
          break;
        default:
          console.log("Unknown item type");
      }
    }
  },[selectedItem,structure])
  React.useEffect(() => {
    forceOpenState(expandAll);
  }, [expandAll]);


  React.useEffect(() => {
    switch (layer) {
      case 1: {
        return setPadding('15.16px');
      }
      case 2: {
        return setPadding('24px');
      }
      case 3: {
        return setPadding('40px');
      }
      default: {
        return setPadding('0');
      }
    }
  }, [layer]);

  const handleExpansion = (item) => {
    switch(item.type) {
      case 'unit':
        setStructure((prevData) => {
          let previousData = prevData.map((unit) => {
            if (unit.id === item.id) {
              return { ...unit, isExpanded: !unit.isExpanded,childrenElements: unit.childrenElements?.map(child => ({ ...child, isExpanded: false }))  };
            }
            return {...unit,isExpanded:false,
              childrenElements: unit.childrenElements?.map(child => ({ ...child, isExpanded: false })) 
            };
          });
          return previousData;
        
        });

        break;

      case 'chapter':  
      if(item.unitId){
      setStructure((prevData) => {
          let previousData = prevData.map((unit) => {
            if (unit.id === item.unitId) {
              const modifiedChildren = unit.childrenElements.map((element)=>{
                   if(element.id === item.id){
                    return { ...element, isExpanded: !element.isExpanded,childrenElements: element.childrenElements?.map(child => ({ 
                      ...child, isExpanded: false })) };
                   }
                   return {...element, isExpanded :false,
                    childrenElements: element.childrenElements?.map(child => ({ ...child, isExpanded: false })) 
                   }  
              })
              return {...unit,childrenElements:modifiedChildren}
            }
            return unit;
          });
          return previousData;
        });
      }
      else{
        setStructure((prevData) => {
          let previousData = prevData.map((chapter) => {
            if (chapter.id === item.id) {
              return { ...chapter, isExpanded: !chapter.isExpanded };
            }
            return {...chapter,isExpanded:false,
              childrenElements: chapter.childrenElements?.map(child => ({ ...child, isExpanded: false })) 
          };
          });
          return previousData;
        
        });
      }
        break;
        
        case "snack":
          if(item.unitId){          
          setStructure((prevData) => {
            let previousData = prevData.map((unit) => {  
              if (unit.id === item.unitId) {
                let modifiedUnitsChildren= unit.childrenElements.map((element)=>{
                     if(element.id === item.chapterId){
                     let modifiedSnacksChildren =element.childrenElements.map((snackElements)=>{
                          if(snackElements.id ===item.id){
                            return { ...snackElements, isExpanded: !snackElements.isExpanded, };
                          }
                          return {...snackElements, isExpanded: false };   
                      })
                      return {...element,childrenElements:modifiedSnacksChildren};   

                    }
                     return {...element,isExpanded: false}  
                })
                return {...unit,childrenElements:modifiedUnitsChildren};   
              }
            
              return unit;
            });
            return previousData;
          }); 
        }
        else{
          setStructure((prevData) => {
            let previousData = prevData.map((chapter) => {  
                let modifiedUnitsChildren= chapter.childrenElements.map((element)=>{
                     if(element.id === item.chapterId){
                     let modifiedSnacksChildren =element.childrenElements.map((snackElements)=>{
                          if(snackElements.id ===item.id){
                            return { ...snackElements, isExpanded: !snackElements.isExpanded, };
                          }
                          return {...snackElements, isExpanded: false };   
                      })
                      return {...element,childrenElements:modifiedSnacksChildren};   

                    }
                    if(element.id === item.id){
                       return {...element,isExpanded: !element.isExpanded,};   
 
                     }
                     return {...element,isExpanded: false}  
                })
                return {...chapter,childrenElements:modifiedUnitsChildren};   
            
            });
            return previousData;
          }); 
        }
        break;
        default:
          return null;
      }
  
  };
    const handleItemClick = () => {   
    setSelected({
      label: label,
      layer: layer,
    });
    setSelectedItem(item)

    if(isNotes){
      Mixpanel.track(
        `Notes : ${name} | Type : ${type} | | Layer : ${layer} | Action : Clicked`,
        {
          button_name: `${name} Card`,
          page: "Notes",
        }
      );
    }

    if (IsMobile) {
      handleOpenMobileDropDown();
    }

    let isExpanded = !item.isExpanded;
    handleExpansion(item);
    listItemClickEvent({
      ...props,
      name,
      type,
      isExpanded,
      label,
      nested,
      layer,
    });
    if (isLibrary) {
      dispatch(
        notesDropdownActions.setMyLibraryData({
          layer,
          name,
          type,
          isExpanded,
          label,
          nested,
          id: props.id,
          open: isExpanded
        })
      );
    } else {
      dispatch(
        notesDropdownActions.setDataforOpen({
          layer,
          name,
          type,
          isExpanded,
          label,
          nested,
          id: props.id,
          setOpen: isExpanded,
          open: isExpanded
        })
      );
    }
  }
  return (
    <>
      {/* Mobile */}

      <StyledItemMobile
        onClick={(e) =>handleItemClick()}
        layer={layer}
        padding={padding}
        active={item.isExpanded}
        selected={item.isExpanded}
          open={item.isExpanded}
      >
        <Stack
          direction="row"
          alignItems={'center'}
          gap={nested ? 2 : 0}
          marginLeft={'15px'}
        >
          {nested ? (
            <AccordianMobileSvg
              // click={(e) => {
              //   setOpen((prevState) => !prevState);
              // }}
              open={nested && item.isExpanded }
            />
          ) : (
            <MobileListIcon isactive={!item.isExpanded} />
          )}

          <ListItemText
            sx={{ cursor: 'pointer' }}
            secondary={secondary ? secondary : null}
            primaryTypographyProps={{
              style: {
                fontFamily: 'Inter',
                overflow: 'hidden',
                color: '#11263c',
                fontFeatureSettings: " 'clig' off 'liga' off",
                fontSize: '14px',
              },
            }}
          >
            {label ? label : name}
          </ListItemText>
        </Stack>
      </StyledItemMobile>

      {nested && (
        <Collapse  in={item.isExpanded} timeout="auto" unmountOnExit>
          <ListComp
            structure={structure}
            setStructure={setStructure}
            open={expandAll}
            onItemClick={listItemClickEvent}
            listSkeleton={childrenElements}
            isLibrary={isLibrary}
            active={item.isExpanded}
            handleOpenMobileDropDown={handleOpenMobileDropDown}
            isAssesment={isAssesment}
            selection={selection}
            selectedData={selectedData}
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
            setSelectedData={setSelectedData}
            isNotes={isNotes}
          />
        </Collapse>
      )}
    </>
  );
};

const ListComp = ({
  structure,
  setStructure,
  heading,
  listSkeleton,
  onItemClick,
  selection,
  open,
  setOpen,
  isFlashcard,
  isLibrary,
  active,
  handleOpenMobileDropDown,
  isAssesment,
  selectedData,
  activeScreen,
  setActiveScreen,
  setSelectedData,
  isNotes
}) => {
  const { data, myLibraryData } = useSelector((state) => state.notesDropdown);
  function isIdPresentRecursive(elements, idToCheck) {
    return elements?.some((element) => {
      if (element.id === idToCheck) {
        return true;
      }

      if (element.childrenElements) {
        return isIdPresentRecursive(element.childrenElements, idToCheck);
      }

      return false;
    });
  }

  return (
    <>
      <StyledListContainerMobile
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          heading !== '' && (
            <ListSubheader component="div" id="nested-list-subheader">
              {heading}
            </ListSubheader>
          )
        }
      >
        {listSkeleton.map((item, index) => {
          let isIdPresent;

          if (isLibrary) {
            isIdPresent =
              item.id === myLibraryData.id ||
              isIdPresentRecursive(item.childrenElements, myLibraryData.id);
          } else {
            isIdPresent =
              item.id === data.id ||
              isIdPresentRecursive(item.childrenElements, data.id);
          }

          return (
            <React.Fragment key={index}>
              {item.name === 'divider' ? (
                <Divider />
              ) : (
                <ListItemComp
                  setStructure={setStructure}
                  structure = {structure}
                  item={item}                
                  openlist={isIdPresent}
                  selection={selection}
                  expandAll={open}
                  setopen={setOpen}
                  listItemClickEvent={onItemClick}
                  nested={item.childrenElements}
                  isFlashcard={isFlashcard}
                  isLibrary={isLibrary}
                  active={active}
                  handleOpenMobileDropDown={handleOpenMobileDropDown}
                  isAssesment={isAssesment}
                  selectedData={selectedData}
                  activeScreen={activeScreen}
                  setActiveScreen={setActiveScreen}
                  setSelectedData={setSelectedData}
                  isNotes={isNotes}
                  {...item}
                />
              )}
            </React.Fragment>
          );
        })}
      </StyledListContainerMobile>
    </>
  );
};

export default ListComp;

ListComp.propTypes = {
  heading: PropTypes.string,
  setStructure: PropTypes.func, 
  listSkeleton: PropTypes.array,
  onItemClick: PropTypes.func,
};
ListComp.defaultProps = {
  ...defaultProps,
};
