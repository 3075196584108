import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoutes';
import { useSelector } from 'react-redux';
import paths from './../Containers/Subject/paths';
import ErrorPage from './../Containers/Home/ErrorPage';
import Loader from '../UI/Loader/Loader';
import LogoLoader from '../UI/Loader/logoLoader';
import { Box } from '@mui/material';

const ChatBot = lazy(() => import('../Containers/AichatBot/ChatBot'));
const Login = lazy(() => import('../Containers/Auth/signin/Login/Login'));
const AiChatBot = lazy(() => import('../Containers/ChatBot/AiChatBot'));
const Forget = lazy(() => import('../Containers/Auth/signin/Forget/Forget'));
const LinkReset = lazy(() =>
  import('../Containers/Auth/signin/LinkReset/LinkReset')
);
const ResetPassword = lazy(() =>
  import('../Containers/Auth/signin/ResetPassword/ResetPassword')
);
const SinUp = lazy(() => import('../Containers/Auth/signup/Stepper/Stepper'));
const VerifyPassword = lazy(() =>
  import('../Containers/Auth/signup/VerifyPassword/VerifyPassword')
);
const Dashboard = lazy(() => import('../Containers/Dashboard/Dashboard'));
const Subject = lazy(() => import('../Containers/Subject/Subject'));
const Users = lazy(() => import('./../Containers/Users/Users'));
const Auth = lazy(() => import('./../Containers/Auth/Auth'));

const ApplicationRoutes = () => {
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  return (
    <Suspense
      fallback={
        <Box sx={{ position: 'absolute', top: '30%', left: '45%' }}>
          <LogoLoader time={'1s'} />
        </Box>
      }
    >
      <Routes>
        <Route
          path="/auth"
          element={
            isLoggedIn === 'true' ? <Navigate to="/dashboard" /> : <Auth />
          }
        >
          <Route index path="" element={<Login />} />
          <Route path="link_reset" element={<LinkReset />} />
          <Route path="forget" element={<Forget />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="signup_internal" element={<SinUp />} />
          <Route path="verify-password" element={<VerifyPassword />} />
        </Route>
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="/octibot" element={<ChatBot />} />
          <Route path="/chatBot" element={<AiChatBot />} />
          <Route path="user" element={<Users />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dashboard/:name/:id/:value" element={<Subject />}>
            <Route
              path=""
              element={<Navigate to={paths[0].name} replace={true} />}
            />
            {paths.map((tab, index) => (
              <Route
                key={index}
                path={`${tab.name}/:tab`}
                element={tab.content}
              />
            ))}
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};

export default ApplicationRoutes;
