import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownIcon from './icons/ArrowDownIconSvg';
import ClickAwayListener from 'react-click-away-listener';
import {
  Divider,
  DropDownContainer,
  ErrorMessage,
  formattedDefaultData,
  Icon,
  Inner,
  ListItem,
  Placeholder,
  CustomAutocompletePaper,
  StyledPaper,
  Wrapper,
} from './SelectComp.style';
import isEmptyObj from '../../../helpers/objectIsEmpty';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const SelectComp = ({
  fullWidth,
  width,
  options,
  placeholder,
  errorMessageText,
  hasError,
  getValue,
  label,
  elevation,
  defaultLabel,
  defaultValue,
  initialValue,
}) => {
  const data = initialValue;
  const [selectedVal, setSelectedVal] = React.useState(data);
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(options);

  React.useEffect(() => {
    if (data !== '') {
      setSelectedVal(data);
    }
    if (!isEmptyObj(defaultValue) && defaultValue) {
      setSelectedVal(defaultValue.value);
    }
  }, [data, defaultValue]);

  React.useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  const handleChange = (event, newValue) => {
    if (newValue) {
      setSelectedVal(newValue.value || '');
      getValue(newValue);
    }
  };

  const handleInputChange = (event, value) => {
    setSearchTerm(value);
    if (value == '') {
      setSelectedVal('');
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setSelectOpen(false)}>
      <Wrapper width={fullWidth ? '100%' : width}>
        {defaultLabel ? (
          <>{label}</>
        ) : (
          <span style={{ fontSize: '0.8rem' }}>{label}</span>
        )}
        <StyledPaper
          error={+hasError}
          type="select"
          selectopen={+selectOpen}
          elevation={elevation}
        >
          <Autocomplete
            PaperComponent={CustomAutocompletePaper}
            options={filteredOptions}
            getOptionLabel={(option) => option.label}
            value={
              filteredOptions.find((option) => option.value === selectedVal) ||
              null
            }
            onChange={handleChange}
            open={selectOpen}
            onOpen={() => setSelectOpen(true)}
            onClose={() => setSelectOpen(false)}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                error={hasError}
                sx={{
                  input: {
                    color: 'rgb(224, 21, 162)',

                    '&::placeholder': {
                      color: 'rgb(224, 21, 162)',
                    },
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  endAdornment: (
                    <Icon type="select" open={+selectOpen}>
                      <ArrowDownIcon />
                    </Icon>
                  ),
                }}
                fullWidth
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props}>{option.label}</ListItem>
            )}
            fullWidth
          />
        </StyledPaper>
        {hasError && <ErrorMessage>{errorMessageText}</ErrorMessage>}
      </Wrapper>
    </ClickAwayListener>
  );
};

SelectComp.propTypes = {
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessageText: PropTypes.string,
  hasError: PropTypes.bool,
  getValue: PropTypes.func,
  defaultLabel: PropTypes.bool,
  defaultValue: PropTypes.object,
  initialValue: PropTypes.string,
};

SelectComp.defaultProps = {
  fullWidth: false,
  width: '30rem',
  options: formattedDefaultData,
  placeholder: '',
  errorMessageText: 'All fields are mandatory!',
  hasError: false,
  defaultValue: {},
  label: 'label',
  initialValue: '',
  getValue: (e) => {
    return;
  },
  defaultLabel: true,
};

export default SelectComp;
