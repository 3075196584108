import React from 'react';
import { StyledBtn , MyLibraryThreeDotBtn } from './IconButton.style';

const IconButtonComp = ({ children, ...props }) => {
  return (
    <MyLibraryThreeDotBtn style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      background: props.show
        ? props.isMenuOpen
          ? '#624BA2'
          : '#E015A2'
        : '',
      borderRadius: props.show ? '100%' : '',
      '&:hover': {
        backgroundColor: props.show ? '#e0dbec!important' : "", // Example hover background color
      },
    }} show={props.show} isMenuOpen={props.isMenuOpen}>
                <StyledBtn {...props}>{children}</StyledBtn>
    </MyLibraryThreeDotBtn>
  );
};

export default IconButtonComp;
