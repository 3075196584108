import React from 'react';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';
import { Box, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import capitalize from '../../../helpers/capitalize';
import CustomLoader from '../CustomLoader/CustomLoader';
import FlashCardStack from './../FlashCardStack/FlashCardStack';
import MobileDetector from '../../../helpers/mobileDetector.jsx';
import doneImage from '../../../assets/images/flashcard/Saly-8.png';
import notStart from '../../../assets/images/flashcard/mask_pipe.webp';
import inProgress from '../../../assets/images/flashcard/topicTitle.webp';
import StarSvg from './../../Containers/Subject/Headers/LearningResources/icons/StarSvg';
import DashboardSvg from './../../Containers/Subject/Headers/LearningResources/icons/DashboardSvg';
import { FlashCardTagsButtonMobile } from '../../Containers/Subject/Headers/LearningResources/FlashCard/FlashCard.Style.js';

import {
  DivHeading,
  MobileFlashHeader,
  MobileTypographyStats,
  MobileTypographyName,
  TopicTitleMobile,
  IconTextWrapperMobile,
  IconText,
  MobileTypographyStatsCompleted,
  MobileTypographyStatsdone,
  IconTextTotal,
} from './FlashCard.style';

const FlashCard = ({
  stackID,
  title,
  clicked,
  type,
  selectedTag,
  loading,
  show,
  setShow,
  flashcardStatus,
  specificFlashcards,
  heading,
  snackId,
  snackTagId,
}) => {
  const isMobile = MobileDetector();
  return (
    <>
      <FlashCardStack
        onClick={(e) => {
          let name = capitalize(type) + ' - ' + heading;
          clicked({ stackID, title, type, specificFlashcards, name });
        }}
        isOctilearnLib={true}
      >
        <MobileFlashHeader>
          <MobileTypographyName>
            {capitalize(type)} - {heading}
          </MobileTypographyName>
          {flashcardStatus.doneFlashcards === 0 ? (
            <MobileTypographyStats style={{ minWidth: 95 }}>
              {'Not Started Yet'}
            </MobileTypographyStats>
          ) : flashcardStatus.doneFlashcards < specificFlashcards.length ? (
            <MobileTypographyStatsCompleted>
              {' '}
              {'In Progress'}
            </MobileTypographyStatsCompleted>
          ) : flashcardStatus.doneFlashcards === specificFlashcards.length ? (
            <MobileTypographyStatsdone>{'Completed'}</MobileTypographyStatsdone>
          ) : (
            'Score'
          )}
        </MobileFlashHeader>

        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DivHeading>
              <div>
                <TopicTitleMobile variant="h5" sx={{ marginBottom: '15px' }}>
                  {capitalize(title)}
                </TopicTitleMobile>
                <Divider
                  sx={{
                    width: '60%',
                    borderColor: 'rgba(191, 56, 149, 0.13)',
                    marginBottom: '10px',
                  }}
                />

                {type !== 'snack' && (
                  <FlashCardTagsButtonMobile>
                    {capitalize(selectedTag?.title)}
                  </FlashCardTagsButtonMobile>
                )}

                <Box sx={{ marginTop: '20px' }}>
                  <Box>
                    <IconTextWrapperMobile>
                      <DashboardSvg isMobile={isMobile} />
                      <Stack direction="row">
                        <IconTextTotal>
                          {specificFlashcards?.length < 9
                            ? '0' + specificFlashcards?.length
                            : specificFlashcards?.length}
                          {'  '}
                        </IconTextTotal>
                        <IconText>
                          {'  '}
                          {specificFlashcards?.length === 1
                            ? ' Flashcard'
                            : ' Flashcards'}
                        </IconText>
                      </Stack>
                    </IconTextWrapperMobile>
                    <IconTextWrapperMobile
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!show && !loading) {
                          setShow(e, snackId, snackTagId);
                        }
                      }}
                    >
                      {loading ? (
                        <CustomLoader
                          variant="tail-spin"
                          height="18px"
                          width="18px"
                          color="#624ba2"
                        />
                      ) : (
                        <StarSvg show={show} isMobile={isMobile} />
                      )}

                      <IconText show={show}>
                        {show ? 'Copied to My Library' : 'Save to My Library'}
                      </IconText>
                    </IconTextWrapperMobile>
                  </Box>
                  <Box>
                    {flashcardStatus.doneFlashcards === 0 ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: isMobile ? '30px' : '-40px',
                          right: '0px',
                        }}
                      >
                        <img
                          src={inProgress}
                          alt="In Progress"
                          style={{
                            width: isMobile ? '120px' : '220px',
                          }}
                        />
                      </Box>
                    ) : flashcardStatus.doneFlashcards <
                      specificFlashcards.length ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: isMobile ? '20px' : '10px',
                          right: '0px',
                        }}
                      >
                        <img
                          src={notStart}
                          alt="Not Started Yet"
                          style={{
                            width: isMobile ? '120px' : '180px',
                          }}
                        />
                      </Box>
                    ) : flashcardStatus.doneFlashcards ===
                      specificFlashcards.length ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: '-5px',
                          right: isMobile ? '-20px' : '-10px',
                        }}
                      >
                        <img src={doneImage} alt="completed" />
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
              </div>
            </DivHeading>
          </Grid>
        </Grid>
      </FlashCardStack>
    </>
  );
};

export default FlashCard;

FlashCard.propTypes = {
  _1000px: PropTypes.any,
  clicked: PropTypes.func,
  data: PropTypes.any,
  id: PropTypes.any,
  image: PropTypes.any,
  questions: PropTypes.any,
  questionsArr: PropTypes.any,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  stackID: PropTypes.any,
  title: PropTypes.any,
  type: PropTypes.string,
  flashcardStatus: PropTypes.object,
};

FlashCard.defaultProps = {
  type: 'chapter',
  clicked: (e) => {
    return;
  },
  loading: false,
  flashcardStatus: { status: 'Not yet Started!', doneFlashcards: 0 },
};
