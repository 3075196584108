import Filter from './components/Filter';
import 'react-calendar/dist/Calendar.css';
import { useParams } from 'react-router-dom';
import 'react-date-picker/dist/DatePicker.css';
import BreadCrumb from '../../../../BreadCrumb';
import api from '../../../../../../../Services';
import ReviewScreen from './screens/ReviewScreen';
import ResultScreen from './screens/ResultScreen';
import { Box, useMediaQuery } from '@mui/material';
import { QuestionModal } from './Assessment.style';
import React, { useEffect, useState } from 'react';
import QuestionScreen from './screens/QuestionScreen';
import GridComp from '../../../../../../UI/Grid/Grid';
import CardComp from '../../../../../../UI/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeScreen from './components/WelcomeScreen';
import LeftSide from '../../../../LeftSideBar/LeftSide';
import Loader from '../../../../../../UI/Loader/Loader';
import AssessmentScreen from './screens/AssessmentScreen';
import MobileDetector from '../../../../../../../helpers/mobileDetector';
import useUserResponseAwait from '../../../../hooks/useUserResponseAwait';
import MobileBottomBar from '../../../../../../mobileBottomBar/MobileBottomBar';
import { assessmentAction } from '../../../../../../../redux/reducers/assessment';
import MobileCourseIndicator from '../../../../../../mobileBottomBar/MobileCourseIndicator';

const Assessment = () => {
  const params = useParams();
  const { id: courseId } = params;
  const [newSelection, setNewSelection] = useState(null);
  const [assessmentView, setAssessmentView] = useState(false);
  const [hierarchyData, setHeirarchyData] = useState(null);
  const [hasAssessment, setHasAssessment] = useState(true);
  const [activeScreen, setActiveScreen] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [activeItem, setActiveItem] = useState('');
  const [userAttemptedQuestins, setUserAttemptedQuestions] = useState([]);
  const [structuredData,setStructuredData] = useState([])

  const [questionsBoard, setQuestionsBoard] = useState({
    totalQuestions: 0,
    attemptedQuestions: 0,
    remainingQuestions: null,
  });
  const [openMobileBar, setOpenMobileBar] = useState(false);
  const { isMobile } = MobileDetector();
  const { awaitingUserResponse, toggleLoading } = useUserResponseAwait();
  const [allQuestions, setAllQuestions] = useState(null);

  const [completionModal, setCompletionModel] = useState(false);

  const dispatch = useDispatch();

  const course = useSelector((state) => state.course);

  const initialLImit = new Date().getFullYear() - 5;
  const handleOpenMobileBar = () => {
    setOpenMobileBar(!openMobileBar);
  };
  const [filter, setFiler] = useState({
    from: initialLImit?.toString(),
    to: new Date(),
  });
  const [updatedFilter, setUpdatedFilter] = useState({
    from: 2019,
    to: 2024,
  });

  const userId = useSelector((state) => state.user.id);

  const _1000px = useMediaQuery('(min-width: 1000px)');
  const _750px = useMediaQuery('(max-width: 750px)');

  const left = _1000px ? 3 : _750px ? 12 : 4;
  const right = _1000px ? 9 : _750px ? 12 : 8;


  const handleExpansion = (item,structure) => {
    switch(item.type) {
      case 'unit':
          const updatedData = structure.map((unit) => {
            if (unit.id === item.id) {
              return { ...unit, isExpanded: !unit.isExpanded , nested : unit.childrenElements   };
            }
            return {...unit,isExpanded:false, nested : unit.childrenElements};
          });
          return updatedData;
        break;

      case 'chapter':  
      if(item.unitId){
          let updatedData = structure.map((unit) => {
            if (unit.id === item.unitId) {
              const modifiedChildren = unit.childrenElements.map((element)=>{
                   if(element.id === item.id){
                    return { ...element, isExpanded: !element.isExpanded, nested : element.childrenElements };
                   }
                   return {...element, isExpanded :false,  nested : element.childrenElements}  
              })
              return {...unit,childrenElements:modifiedChildren ,  nested :modifiedChildren}
            }
          
            return unit;
          });
          return updatedData;
      }
      else{
          let updatedData = structure.map((chapter) => {
            if (chapter.id === item.id) {
              return { ...chapter, isExpanded: !chapter.isExpanded ,nested : chapter.childrenElements };
            }
            return {...chapter,isExpanded:false,nested : chapter.childrenElements};
          });
          return updatedData;
      }
        break;
        
        case "snack":
            const updatedDataSnack = structure.map((unit) => {  
              if (unit.id === item.unitId) {
                let modifiedUnitsChildren= unit.childrenElements.map((element)=>{
                     if(element.id === item.chapterId){
                     let modifiedSnacksChildren =element.childrenElements.map((snackElements)=>{
                          if(snackElements.id ===item.id){
                            return { ...snackElements, isExpanded: !snackElements.isExpanded, nested : snackElements.childrenElements };
                          }
                          return snackElements;   
                      })
                      return {...element,childrenElements:modifiedSnacksChildren , nested : modifiedSnacksChildren};   

                    }
                     return element  
                })
                return {...unit,childrenElements:modifiedUnitsChildren, nested : modifiedUnitsChildren};   
              }
            
              return unit;
            });
            return updatedDataSnack;
      default:
        console.log("Unknown item type");
    }
  
  };

  //  Fetch Chapter Hierarchy
  const fetchAssessmentHierarchy = async () => {
    let token = localStorage.getItem('auth');
    await api
      .getHierarchyForAssessment(token, courseId)
      .then((resp) => {
        setHeirarchyData(resp);
      })
      .catch((err) => console.error(err));
  };

  // Get Quiz Questions
  const getAllQuestions = async (id, type) => {
    toggleLoading(true);

    let token = localStorage.getItem('auth');
    await api
      .getQuestions(token, id, updatedFilter, type)
      .then((res) => {
        setAllQuestions(res?.data);
        toggleLoading(false);
        getUserAttemptQuestions(res?.data);
      })
      .catch((err) => console.error(err));
  };

  function handleSelectedData(e) {
    if (e.type === 'unit') {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: 'unit',
        active: 'unit',
      });
    } else if (e.type === 'chapter') {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        chaptername: e.name,
        type: 'chapter',
        active: 'chapter',
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        snackname: e.name,
        type: 'snack',
        active: 'snack',
      }));
    }
  }

  function handleSelection(e) {
    const assessment = handleExpansion(e,structuredData)
    const assessmentGlobal = {
      data: assessment, 
      tab:"assessment",
      selection : e
    };
    localStorage.setItem('assessmentGlobalState', JSON.stringify(assessmentGlobal));
    handleSelectedData(e)
  }

  
  const listItemClickHandler = (selectedListItem, isStorage=false) => {
    if(isStorage){

    
    if (selectedListItem.isExpanded) {
      getAllQuestions(selectedListItem?.id, selectedListItem?.type);
      setHasAssessment(false);
    }
    if(selectedListItem.layer === 2 && !selectedListItem.isExpanded){
      const firstLevelData = structuredData?.filter((unit) => {    
      return unit.id === selectedListItem.unitId
      }); 
      firstLevelData[0].nested = firstLevelData[0]?.childrenElements
      getAllQuestions(firstLevelData[0]?.id,firstLevelData[0]?.type);
      setActiveItem(firstLevelData[0]?.label);
      setNewSelection(firstLevelData[0]);
    }
    else{
      setActiveItem(selectedListItem.label);
      handleSelectedData(selectedListItem);
      setNewSelection(selectedListItem);
    }
  }
  else{
    if (selectedListItem.isExpanded) {
      getAllQuestions(selectedListItem?.id, selectedListItem?.type);
      setHasAssessment(false);
    }
    if(selectedListItem.layer === 2 && !selectedListItem.isExpanded){
      const firstLevelData = structuredData?.filter((unit) => {    
      return unit.id === selectedListItem.unitId
      }); 
      firstLevelData[0].nested = firstLevelData[0]?.childrenElements
      getAllQuestions(firstLevelData[0]?.id,firstLevelData[0]?.type);
      setActiveItem(firstLevelData[0]?.label);
      setNewSelection(firstLevelData[0]);
    }
    else{
      handleSelection(selectedListItem)
      setActiveItem(selectedListItem.label);
      handleSelectedData(selectedListItem);
      setNewSelection(selectedListItem);
    }
  }
  };


  // Get User Quiz Questions
  const getUserAttemptQuestions = async (data) => {
    toggleLoading(true);

    let token = localStorage.getItem('auth');
    await api
      .getUserQuestions(token)
      .then((res) => {
        // Set User Total Questions
        setQuestionsBoard((prev) => {
          return {
            ...prev,
            totalQuestions: data,
          };
        });

        const attemptedQuestions = res?.data?.filter((item) => {
          return data?.some((q) => q?.id === item?.questionId);
        });

        // Set user attempted questions
        setQuestionsBoard((prev) => {
          return {
            ...prev,
            attemptedQuestions: attemptedQuestions,
          };
        });

        // Set User remaining questions
        const newQuestions = data?.filter((item) => {
          return !res?.data?.some((q) => {
            return q?.questionId === item?.id;
          });
        });

        setAllQuestions(newQuestions);

        setQuestionsBoard((prev) => {
          return {
            ...prev,
            remainingQuestions: newQuestions,
          };
        });

        if (activeScreen !== 1) {
          // setUserAttemptedQuestions([]);
        }

        toggleLoading(false);
      })
      .catch((err) => console.error(err));
  };

  // Add user submitted quiz

  const addEditQuiz = async () => {
    if (userAttemptedQuestins?.length > 0) {
      let token = localStorage.getItem('auth');
      const response = await api
        .addEditUserQuiz(token, userAttemptedQuestins)
        .then(() => {
          localStorage.removeItem('attemptedQuestions');
        });
    }
  };

  // Update Top Bar Z-Index

  const updateZIndex = (bool) => {
    dispatch(assessmentAction.updateTopBarZIndex(bool));
  };

  useEffect(() => {
    fetchAssessmentHierarchy();
  }, []);

  useEffect(() => {
    if (newSelection !== null) {
      getAllQuestions(newSelection?.id, newSelection?.type);
    }
  }, [filter]);

  useEffect(() => {
    if (
      activeScreen === 0 &&
      questionsBoard?.remainingQuestions?.length === 0 &&
      questionsBoard?.totalQuestions?.length > 0
    ) {
      setCompletionModel(true);
    } else {
      setCompletionModel(false);
    }
  }, [questionsBoard, newSelection, activeScreen]);

  useEffect(() => {
    let q = localStorage.getItem('attemptedQuestions');

    q = JSON.parse(q);

    if (q) {
      if (q.length > 0) {
        setUserAttemptedQuestions(q);
      }
    }
  }, []);

  useEffect(()=>{
    if(selectedData?.layer === 1 && selectedData?.isExpanded===false){
      setHasAssessment(true)
    }
  },[selectedData])

  return (
    <>
      {assessmentView ? (
        <>
          <QuestionModal>
            <QuestionScreen
              newSelection={newSelection}
              setAssessmentView={setAssessmentView}
              allQuestions={allQuestions}
              updateZIndex={updateZIndex}
              userId={userId}
              addEditQuiz={addEditQuiz}
              userAttemptedQuestins={userAttemptedQuestins}
              setUserAttemptedQuestions={setUserAttemptedQuestions}
              setActiveScreen={setActiveScreen}
            />
          </QuestionModal>
        </>
      ) : (
        <>
          <Box
            sx={{
              padding: { xs: '20px 10px', sm: '0px 0px' },
              marginBottom: { xs: '70px', sm: '0px' },
            }}
          >
            <GridComp container spacing={{ xs: 0, sm: 4 }}>
              {activeScreen === 0 && (
                <>
                  <GridComp item xs={12}>
                    <BreadCrumb />
                  </GridComp>
                </>
              )}

              {activeScreen !== 2 && (
                <GridComp
                  item
                  xs={left}
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                  <CardComp nopadding={true}>
                    {!isMobile && (
                      <LeftSide
                        onListClick={(e, i) => {
                          listItemClickHandler(e, i);
                        }}
                        hierarchyData={hierarchyData}
                        isLibrary={false}
                        isFlashcard={false}
                        isSimulation={false}
                        isAssesment={true}
                        selectedData={selectedData}
                        activeScreen={activeScreen}
                        setActiveScreen={setActiveScreen}
                        setSelectedData={setSelectedData}
                        setStructuredData={setStructuredData}
                        tab={"assessment"}
                      />
                    )}
                  </CardComp>
                </GridComp>
              )}

              <GridComp
                item
                xs={right}
                style={{ minWidth: activeScreen === 2 && '100%' }}
              >
                {hasAssessment ? (
                  <>
                    <WelcomeScreen title={course?.title} />
                    <MobileCourseIndicator tab="Assesments" />
                  </>
                ) : awaitingUserResponse?.loading ? (
                  <Loader />
                ) : (
                  <>
                    {activeScreen === 0 ? (
                      <AssessmentScreen
                        updateZIndex={updateZIndex}
                        newSelection={newSelection}
                        updatedFilter={updatedFilter}
                        completionModal={completionModal}
                        setCompletionModel={setCompletionModel}
                        setAssessmentView={setAssessmentView}
                        setFiler={setFiler}
                        setUpdatedFilter={setUpdatedFilter}
                        filter={filter}
                        totalQuestions={questionsBoard.totalQuestions}
                        attemptedQuestions={questionsBoard.attemptedQuestions}
                        remainingQuestions={questionsBoard.remainingQuestions}
                      />
                    ) : activeScreen === 1 ? (
                      <ReviewScreen
                        allQuestions={allQuestions}
                        course={course}
                        userId={userId}
                        addEditQuiz={addEditQuiz}
                        setActiveScreen={setActiveScreen}
                        userAttemptedQuestins={userAttemptedQuestins}
                        setUserAttemptedQuestions={setUserAttemptedQuestions}
                        newSelection={newSelection}
                      />
                    ) : activeScreen === 2 ? (
                      <ResultScreen
                        allQuestions={allQuestions}
                        userAttemptedQuestins={userAttemptedQuestins}
                        setActiveScreen={setActiveScreen}
                        course={course}
                        newSelection={newSelection}
                      />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </GridComp>
            </GridComp>
          </Box>
          <MobileBottomBar
            selectionText="Click here to get started "
            openMobileDropDown={openMobileBar}
            handleOpenMobileDropDown={handleOpenMobileBar}
            showData={hasAssessment}
            selectedData={selectedData}
            leftSideMenu={
              <LeftSide
                onListClick={(e, i) => {
                  listItemClickHandler(e, i);
                }}
                setStructuredData={setStructuredData}
                hierarchyData={hierarchyData}
                isLibrary={false}
                isFlashcard={false}
                handleOpenMobileDropDown={handleOpenMobileBar}
                isSimulation={false}
                active={activeItem}
                isAssesment={true}
                selectedData={selectedData}
                activeScreen={activeScreen}
                setActiveScreen={setActiveScreen}
                setHasAssessment={setHasAssessment}
                setSelectedData={setSelectedData}
                tab={"assessment"}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default Assessment;
