import React from "react";

const MessageIconSvg = ({ focused, ...props }) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={focused ? "1" : "0.2"}>
        <g clipPath="url(#clip0_2489_24249)">
          <path
            d="M3.54061 12.3818C2.2467 11.1109 1.45554 9.464 1.27185 7.6875C0.584165 8.55681 0.211289 9.62304 0.213008 10.742C0.214164 11.4699 0.377977 12.1933 0.688384 12.8475L0.0483203 14.7103C-0.0617112 15.0305 0.0186639 15.3782 0.258102 15.6176C0.426602 15.7861 0.648759 15.8759 0.877228 15.8759C0.973353 15.8759 1.0706 15.86 1.16545 15.8274L3.02823 15.1873C3.68245 15.4977 4.40592 15.6615 5.13374 15.6627C6.27465 15.6642 7.36049 15.2771 8.23877 14.5637C6.47277 14.4039 4.82536 13.6436 3.54061 12.3818Z"
            fill="white"
          />
          <path
            d="M15.9423 12.5572L15.0131 9.8528C15.461 8.93711 15.6977 7.91873 15.6993 6.89408C15.7021 5.11242 15.0127 3.42782 13.7583 2.15063C12.5036 0.873159 10.8317 0.154095 9.05042 0.125876C7.20338 0.0966572 5.46725 0.799221 4.16203 2.10441C2.85681 3.40963 2.15412 5.14573 2.18337 6.99286C2.21159 8.77408 2.93066 10.4461 4.20806 11.7007C5.48275 12.9527 7.16307 13.6419 8.94092 13.6418C8.94442 13.6418 8.9481 13.6418 8.95154 13.6418C9.97623 13.6402 10.9946 13.4035 11.9103 12.9556L14.6148 13.8849C14.7275 13.9236 14.8431 13.9425 14.9573 13.9425C15.2288 13.9425 15.4928 13.8358 15.6931 13.6355C15.9776 13.351 16.0731 12.9378 15.9423 12.5572ZM9.59382 9.22868H6.10404C5.84104 9.22868 5.62785 9.01546 5.62785 8.75249C5.62785 8.48949 5.84107 8.2763 6.10404 8.2763H9.59379C9.85679 8.2763 10.07 8.48952 10.07 8.75249C10.07 9.01549 9.85682 9.22868 9.59382 9.22868ZM11.7778 7.27026H6.10407C5.84107 7.27026 5.62788 7.05705 5.62788 6.79408C5.62788 6.53111 5.8411 6.31789 6.10407 6.31789H11.7778C12.0408 6.31789 12.254 6.53111 12.254 6.79408C12.254 7.05705 12.0408 7.27026 11.7778 7.27026ZM11.7778 5.31179H6.10407C5.84107 5.31179 5.62788 5.09857 5.62788 4.8356C5.62788 4.5726 5.8411 4.35942 6.10407 4.35942H11.7778C12.0408 4.35942 12.254 4.57263 12.254 4.8356C12.254 5.09857 12.0408 5.31179 11.7778 5.31179Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2489_24249">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MessageIconSvg;

MessageIconSvg.defaultProps = {
  focused: false,
};
