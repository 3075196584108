import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '../../../../../../UI/Typography/TypographyCompo';
import Button from '../../../../../../UI/Button/ButtonComp';
import ToolTips from '../../../../../../UI/Tooltip/ToolTip';
import Palette from '../../../../../../../config/palette.js';
import RightBarAccordian from '../../../../../../UI/RightBarAccordian/RightBarAccordian';
import capitalize from '../../../../../../../helpers/capitalize';
import MoveLibrarySvg from '../../../../LeftSideBar/Icons/MoveLibrarySvg';
import Stack from '../../../../../../UI/Stack/Stack';
import RenderNote from './RenderNote';
import { useSnackbar } from 'notistack';
import { Mixpanel } from '../../../../../../../helpers/Mixpanel.js';
import { formatText } from '../../../../../../UI/List/Utils.js';
import {
  MobileDivider,
  MobileWrapper,
  MoveToLibraryButtonMobile,
  NotesContentBox,
  PrimaryNameMobile,
  SecondaryNameMobile,
} from './NotesContent.style.js';
import { Box, Divider } from '@mui/material';
import MobileModal from '../../../../../../mobileModal/mobileModal.jsx';
const Wrapper = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  background: 'white',
  width: '100%',
  display: 'block',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const MoveToLibraryButton = styled(Button)(({ theme, isCopied, show }) => ({
  display: show ? 'flex' : 'none',
  background: isCopied ? '#624BA2' : theme.palette.secondary.main,
  borderRadius: '10px',
  fontSize: '14px',
  height: '40px',
  fontFamily: 'Inter',
  cursor: isCopied ? 'not-allowed' : 'pointer',
  color: 'white',
  '&:hover': {
    background: !isCopied ? theme.palette.secondary.main : '',
  },

  '@media (max-width: 600px)': {
    fontSize: '10px',
  },
  '@media (min-width: 601px) and (max-width: 1200px)': {
    fontSize: '12px',
  },
  '@media (min-width: 12S01px)': {
    fontSize: '14px',
  },
}));

export const MoveToLibraryButtonStyled = styled(MoveToLibraryButton)(
  ({ theme }) => ({
    display: 'flex',
    alignSelf: 'flex-end',
    background: '#fff',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.light}`,
    boxShadow: 'none',
    '&:hover': {
      background: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  })
);

const NotesContent = ({
  chapterId,
  selection,
  data,
  title,
  tags,
  addToLibraryHandler,
  name,
  isInLibrary,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [allowCopy, setAllowCopy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [controller, setController] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const isMobileScreen = window.innerWidth <= 700;
  const [isCopied, setisCopied] = useState(isInLibrary);

  const buildController = (tagsArr, payload, index) => {
    const filteredTags = tagsArr.filter((eachTag) =>
      payload.some((eachNote) => eachNote.tagId === eachTag.id)
    );
    let tempController = filteredTags.map((each) => {
      const bodyIsFound = payload.find((el) => el.tagId === each.id);

      if (bodyIsFound) {
        return {
          title: each.title,
          tagId: bodyIsFound.tagId,
          content: (
            <RenderNote
              type={selection.type}
              tagId={bodyIsFound.tagId}
              noteId={bodyIsFound.id}
              name={selection.name}
              snackId={bodyIsFound.snackId}
              chapterId={bodyIsFound.chapterId}
              addToLibraryHandler={(e) => {
                addToLibraryHandler(e, 'specific-tag');
              }}
              data={bodyIsFound}
              index={index}
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
            />
          ),
        };
      }
      return null;
    });
    setController([...tempController]);
  };

  useEffect(() => {
    if (tags.length > 0) {
      let sorted = tags.sort((a, b) => a.id - b.id);
      buildController(sorted, data);
    }
  }, [selection, tags]);

  useEffect(() => {
    if (allowCopy) {
      setButtonClicked(true);
      setisCopied(true);

      setTooltipVisible(false);
      enqueueSnackbar('Content successfully copied to My Library.', {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      Mixpanel.track(
        `Saved ${formatText(selection.type)} ${formatText(
          selection.name
        )} in My Library Notes`,
        {
          button_name: `Copy to my Library Button`,
          page: "Notes",
          description : `Snack : ${title}`
        }
      );
      addToLibraryHandler({ ...selection, chapterId }, 'full-content');
      setAllowCopy(false);
    }
  }, [allowCopy]);
  return (
    <React.Fragment>
      {selection.name !== '' && selection.name && controller.length !== 0 && (
        <>
          {/* If Mobile View */}
          <MobileWrapper>
            <Stack
              direction="row"
              sx={{
                width: '100wv',
                padding: '0.8rem',
                background: 'rgba(98, 75, 162, 0.09)',
                minHeight: '58px',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                paddingLeft: '20px',
              }}
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="blue"
            >
              {selection.name !== '' && selection.name ? (
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  spacing={1}
                  width="100%"
                >
                  <PrimaryNameMobile variant="h6">
                    {selection.type === 'unit'
                      ? `Unit: ${selection.name}`
                      : selection.type === 'chapter'
                      ? `Chapter: ${name}`
                      : `Snack: ${title}`}
                  </PrimaryNameMobile>

                  <Box>
                    <SecondaryNameMobile variant="body">
                      {selection.type === 'unit'
                        ? `Chapter: ${name}`
                        : selection.type === 'chapter'
                        ? `Snack: ${capitalize(title)}`
                        : null}
                    </SecondaryNameMobile>
                    {selection.type === 'unit' ? <MobileDivider /> : ''}
                    <SecondaryNameMobile variant="body">
                      {selection.type === 'unit'
                        ? `Snack: ${capitalize(title)}`
                        : selection.type === 'chapter'
                        ? ''
                        : selection.type === 'snack'
                        ? ''
                        : null}
                    </SecondaryNameMobile>
                  </Box>
                </Stack>
              ) : (
                <Typography variant="h6">Please choose a topic</Typography>
              )}

              {isMobileScreen ? (
                <>
                  {selection.name !== '' && selection.name && (
                    <>
                      <MoveToLibraryButtonMobile
                        isCopied={isCopied}
                        onMouseOver={() => {
                          if (!buttonClicked) {
                            setTooltipVisible(true);
                          }
                        }}
                        onMouseLeave={() => setTooltipVisible(false)}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isCopied) {
                            setOpenModel(true);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="14"
                          viewBox="0 0 10 14"
                          fill="none"
                        >
                          <path
                            d="M7.2122 13.4999H2.77342C2.14276 13.4999 1.53793 13.2494 1.09198 12.8035C0.646038 12.3575 0.395508 11.7527 0.395508 11.122V2.87857C0.395508 2.2479 0.646038 1.64307 1.09198 1.19712C1.53793 0.751178 2.14276 0.500648 2.77342 0.500648H6.52102C6.64715 0.500648 6.76812 0.550754 6.85731 0.639944C6.9465 0.729133 6.9966 0.850099 6.9966 0.976232C6.9966 1.10236 6.9465 1.22333 6.85731 1.31252C6.76812 1.40171 6.64715 1.45182 6.52102 1.45182H2.77342C2.39503 1.45182 2.03213 1.60213 1.76456 1.8697C1.49699 2.13727 1.34667 2.50017 1.34667 2.87857V11.122C1.34667 11.5004 1.49699 11.8633 1.76456 12.1309C2.03213 12.3984 2.39503 12.5488 2.77342 12.5488H7.2122C7.5906 12.5488 7.9535 12.3984 8.22107 12.1309C8.48864 11.8633 8.63895 11.5004 8.63895 11.122V4.08972C8.63895 3.96359 8.68906 3.84262 8.77825 3.75343C8.86744 3.66424 8.9884 3.61413 9.11454 3.61413C9.24067 3.61413 9.36164 3.66424 9.45082 3.75343C9.54001 3.84262 9.59012 3.96359 9.59012 4.08972V11.122C9.59012 11.7527 9.33959 12.3575 8.89364 12.8035C8.4477 13.2494 7.84287 13.4999 7.2122 13.4999Z"
                            fill="white"
                          />
                          <path
                            d="M6.52163 5.25642H8.53811C8.68584 5.24995 8.83082 5.21418 8.96461 5.15119C9.0984 5.0882 9.21834 4.99926 9.31746 4.88952C9.41658 4.77978 9.4929 4.65144 9.54199 4.51195C9.59108 4.37246 9.61196 4.2246 9.60342 4.07697C9.60254 3.96631 9.56459 3.85914 9.49562 3.7726L6.89576 0.659114C6.84927 0.606342 6.7915 0.564695 6.72675 0.537255C6.66199 0.509815 6.59189 0.497283 6.52163 0.500586C6.22778 0.516983 5.95231 0.648786 5.75516 0.867306C5.55802 1.08583 5.45517 1.37337 5.46901 1.66735V4.08966C5.45517 4.38364 5.55802 4.67118 5.75516 4.8897C5.95231 5.10822 6.22778 5.24002 6.52163 5.25642Z"
                            fill="white"
                          />
                          <path
                            d="M4.51661 10.6894L4.51661 6.88476C4.51661 6.75862 4.56671 6.63766 4.6559 6.54847C4.74509 6.45928 4.86606 6.40917 4.99219 6.40917C5.11832 6.40917 5.23929 6.45928 5.32848 6.54847C5.41767 6.63766 5.46777 6.75862 5.46777 6.88476L5.46777 10.6894C5.46777 10.8156 5.41767 10.9365 5.32848 11.0257C5.23929 11.1149 5.11832 11.165 4.99219 11.165C4.86606 11.165 4.74509 11.1149 4.6559 11.0257C4.56671 10.9365 4.51661 10.8156 4.51661 10.6894Z"
                            fill="white"
                          />
                          <path
                            d="M6.89549 9.13307H3.09082C2.96469 9.13307 2.84372 9.08296 2.75453 8.99377C2.66534 8.90458 2.61523 8.78362 2.61523 8.65748C2.61523 8.53135 2.66534 8.41038 2.75453 8.32119C2.84372 8.23201 2.96469 8.1819 3.09082 8.1819H6.89549C7.02162 8.1819 7.14258 8.23201 7.23177 8.32119C7.32096 8.41038 7.37107 8.53135 7.37107 8.65748C7.37107 8.78362 7.32096 8.90458 7.23177 8.99377C7.14258 9.08296 7.02162 9.13307 6.89549 9.13307Z"
                            fill="white"
                          />
                        </svg>
                      </MoveToLibraryButtonMobile>
                    </>
                  )}
                </>
              ) : (
                <>
                  <MoveToLibraryButton
                    show={true}
                    onMouseOver={() => {
                      if (!buttonClicked) {
                        setTooltipVisible(true);
                      }
                    }}
                    onMouseLeave={() => setTooltipVisible(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isCopied) {
                        setOpenModel(true);
                      }
                    }}
                    sx={{
                      float: 'right',
                      minWidth: '200px',
                      minHeight: '55px',
                      width: { xs: '30%', sm: '25%' },
                    }}
                    startIcon={<MoveLibrarySvg />}
                    fontSize="1px"
                    isCopied={isCopied}
                  >
                    {isCopied ? 'Copied to my library' : 'Copy to My Library'}
                    {!isMobileScreen ? (
                      <ToolTips
                        backgroundColor={Palette.colors.lightColor}
                        arrow={false}
                        placement="bottom-start"
                        color="#E015A2"
                        title={
                          isCopied
                            ? 'Copied to my library'
                            : 'To edit, copy to My Library'
                        }
                        open={tooltipVisible}
                      />
                    ) : (
                      ''
                    )}
                  </MoveToLibraryButton>
                </>
              )}
            </Stack>
          </MobileWrapper>
        </>
      )}
      {selection.name !== '' && selection.name && (
        <React.Fragment>
          {controller && controller.length > 0 && (
            <RightBarAccordian controller={controller} isOctiLibrary={true} />
          )}
        </React.Fragment>
      )}

      <MobileModal
        open={openModel}
        close={setOpenModel}
        modaltext={'Are you sure you want to save it to my library?'}
        isDelete={false}
        warningtext={' '}
        cancelText={'No'}
        isCopy={true}
        succes={() => {
          console.log("succes",name)
          setOpenModel(false);
          setAllowCopy(true);
        }}
        reset={setOpenModel}
      />
    </React.Fragment>
  );
};

export default NotesContent;
