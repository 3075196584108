import React, {useEffect} from 'react';
import List from '../../../UI/List/List';
import api from '../../../../Services';
import IconBar from './../../../UI/List/IconBar';
import Loader from './../../../UI/Loader/Loader';
import ErrorMessage from '../../../UI/ErrorMessage/ErrorMessage';
import { useParams } from 'react-router-dom';
import ErrorBoundary from './../../../../Errors/ErrorBoundary';
import { useSelector, useDispatch } from 'react-redux';
import { learningResourcesNotesListControllerActions } from '../../../../redux/reducers/learningResourcesNotesListController';
import { courseActions } from '../../../../redux/reducers/course';

const LeftSide = ({
  onListClick,
  selectedFromList,
  getMenuStats,
  hierarchyData,
  isLibrary,
  isFlashcard,
  active,
  handleOpenMobileDropDown,
  isNotes,
  isSimulation,
  isAssesment,
  selectedData,
  activeScreen,
  setActiveScreen,
  setSelectedData,
  setStructuredData,
  tab 
}) => {
  const dispatch = useDispatch();
  const open = useSelector(
    (state) => state.learningResourcesNotesListController.value
  );

  const [structure, setStructure] = React.useState([]);
  const [isListDataLoading, setIsListDataLoading] = React.useState(false);
  const [listDataErr, setListDataErr] = React.useState(false);
  const [initLoad, setInitLoad] = React.useState({});
  const { id: courseId } = useParams();
  const toggleOpen = (val) =>
    dispatch(learningResourcesNotesListControllerActions.setValue(val));
  const getMenufromDB = async (token) => {
    let temp = await api.getSyllabus(token, courseId);
    return temp;
  };

  useEffect(()=>{    
    if(setStructuredData){
      setStructuredData(structure)
    }
  },[structure])

  const makeList = (el) => {
    if (el?.statusCode === 200) {
      if (isLibrary) {
        if (el.isUnitsAVailable) {
          let units = el.data;
          units.sort((a, b) => {
            return a.id - b.id;
          });
            const unitsData = units.map((unit) => {
            const unitIds = unit.unitId;
            const unitName = unit.unitName;
            const filteredChapter = unit.chapters.filter(
              (fil) => fil.unitId === unitIds
            );

            filteredChapter.sort((a, b) => {
              return a.id - b.id;
            });

            return {
              type: 'unit',
              layer: 1,
              name: unitName,
              label: unitName,
              id: unitIds,
              isExpanded:false,

              icon: <IconBar />,
              childrenElements:
                filteredChapter.length === 0
                  ? null
                  : [
                      ...filteredChapter.map((chItem) => {
                        const chapterIds = chItem.chapterId;
                        const chapterName = chItem.chapterName;
                        const snakData = [...chItem.snacks];

                        snakData.sort((a, b) => {
                          return a.id - b.id;
                        });
                        const filteredSnack = snakData.filter(
                          (item) => item.chapterId === chapterIds
                        );
                        if (chItem.snacks) {
                          return {
                            type: 'chapter',
                            layer: 2,
                            name: chapterName,
                            unitId: unitIds,
                            id: chapterIds,
                            label: chapterName,
                            isExpanded:false,

                            icon: <IconBar />,
                            childrenElements:
                              filteredSnack.length === 0
                                ? null
                                : [
                                    ...filteredSnack.map(
                                      (snackItem, snackIndex) => {
                                        const snackName = snackItem.snackName;
                                        const snackId = snackItem.snackId;
                                        const tags = snackItem.tags;
                                        return {
                                          type: 'snack',
                                          layer: 3,
                                          name: snackName,
                                          unitId: unitIds,
                                          chapterId: chapterIds,
                                          id: snackId,
                                          isExpanded:false,
                                          icon: <IconBar />,
                                          label: snackName,
                                          tags,
                                        };
                                      }
                                    ),
                                  ],
                          };
                        } else {
                          return {
                            type: 'chapter',
                            layer: 2,
                            name: chapterName,
                            unitId: unitIds,
                            id: chapterIds,
                            label: chapterName,
                            isExpanded:false,
                            
                          };
                        }
                      }),
                    ],
            };
          });
          setStructure(unitsData);
          setIsListDataLoading(false);
        } else {
          el.data.sort((a, b) => {
            return a.id - b.id;
          });
          const chaptersData = el.data.map((chItem, index) => {
            const chapterIds = chItem.chapterId;
            const chapterName = chItem.chapterName;
            const _snakData = [...chItem.snacks];

            _snakData.sort((a, b) => {
              return a.id - b.id;
            });
            const filteredSnackwithoutUnit = _snakData.filter(
              (item) => item.chapterId === chapterIds
            );
            if (chItem.snacks) {
              return {
                type: 'chapter',
                layer: 1,
                name: chapterName,
                id: chapterIds,
                icon: <IconBar />,
                label: chapterName,
                isExpanded:false,
                childrenElements:
                  filteredSnackwithoutUnit.length === 0
                    ? null
                    : [
                        ...filteredSnackwithoutUnit.map((snackItem, _) => {
                          const snackName = snackItem.snackName;
                          const snackId = snackItem.snackId;
                          const tags = snackItem.tags;
                          return {
                            type: 'snack',
                            layer: 2,
                            id: snackId,            
                            name: snackName,
                            icon: <IconBar />,
                            label: snackName,
                            isExpanded:false,
                            tags,
                          };
                        }),
                      ],
              };
            } else {
              return {
                type: 'chapter',
                layer: 1,
                name: chapterName,
                id: chapterIds,
                label: chapterName,
              };
            }
          });
          setStructure(chaptersData);
          setIsListDataLoading(false);
        }
      } else {
        if (el.isUnitsAvailable) {
          let units = el.data;
          units.sort((a, b) => {
            return a.id - b.id;
          });
          const unitsData = units.map((unit) => {
            const unitIds = unit?.unitId ? unit?.unitId : unit?.id;
            const unitName = unit?.unitName ? unit?.unitName : unit?.title;
            const filteredChapter = unit.chapters.filter(
              (fil) => fil.unitId === unitIds
            );

            filteredChapter.sort((a, b) => {
              return a.id - b.id;
            });

            return {
              type: 'unit',
              layer: 1,
              name: unitName,
              label: unitName,
              isExpanded:false,
              id: unitIds,
              icon: <IconBar />,
              childrenElements:
                filteredChapter.length === 0
                  ? null
                  : [
                      ...filteredChapter.map((chItem) => {
                        const chapterIds = chItem?.chapterId
                          ? chItem?.chapterId
                          : chItem?.id;
                        const chapterName = chItem?.chapterName
                          ? chItem?.chapterName
                          : chItem?.title;

                        if (isSimulation === false) {
                          const snakData = [...chItem?.snacks];

                          snakData?.sort((a, b) => {
                            return a.id - b.id;
                          });
                          const filteredSnack = snakData?.filter(
                            (item) => item.chapterId === chapterIds
                          );
                          if (chItem?.snacks) {
                            return {
                              type: 'chapter',
                              layer: 2,
                              name: chapterName,
                              id: chapterIds,
                              unitId: unitIds,
                              label: chapterName,
                              icon: <IconBar />,
                              isExpanded:false,
                              childrenElements:
                                filteredSnack?.length === 0
                                  ? null
                                  : [
                                      ...filteredSnack?.map((snackItem, _) => {
                                        const snackName = snackItem?.snackName
                                          ? snackItem?.snackName
                                          : snackItem?.title;
                                        const snackId = snackItem?.snackId
                                          ? snackItem?.snackId
                                          : snackItem?.id;
                                        return {
                                          type: 'snack',
                                          layer: 3,
                                          name: snackName,
                                          id: snackId,
                                          unitId:unitIds,
                                          chapterId:chapterIds,
                                          icon: <IconBar />,
                                          label: snackName,
                                          isExpanded:false
                                        };
                                      }),
                                    ],
                            };
                          }
                        } else {
                          return {
                            type: 'chapter',
                            layer: 2,
                            name: chapterName,
                            id: chapterIds,
                            unitId:unitIds,
                            label: chapterName,
                            isExpanded:false
                          };
                        }
                      }),
                    ],
            };
          });
          setStructure(unitsData);          
          setIsListDataLoading(false);
        } else {
          if (isNotes) {
            el.data.sort((a, b) => {
              return a.id - b.id;
            });
            const chaptersData = el.data.map((chItem, index) => {
              const chapterIds = chItem.chapterId;
              const chapterName = chItem.chapterName;
              const _snakData = [...chItem.snacks];

              _snakData.sort((a, b) => {
                return a.id - b.id;
              });
              const filteredSnackwithoutUnit = _snakData.filter(
                (item) => item.chapterId === chapterIds
              );
              if (chItem.snacks) {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  icon: <IconBar />,
                  label: chapterName,
                  isExpanded : false,
                  childrenElements:
                    filteredSnackwithoutUnit.length === 0
                      ? null
                      : [
                          ...filteredSnackwithoutUnit.map((snackItem, _) => {
                            const snackName = snackItem.snackName;
                            const snackId = snackItem.snackId;
                            const tags = snackItem.tags;
                            return {
                              type: 'snack',
                              layer: 2,
                              id: snackId,
                              name: snackName,
                              icon: <IconBar />,
                              label: snackName,
                              tags,
                              isExpanded : false,
                              chapterId : chapterIds, 
                            };
                          }),
                        ],
                };
              } else {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  label: chapterName,
                  isExpanded:false
                };
              }
            });
            setStructure(chaptersData);
            setIsListDataLoading(false);
          } else if (isFlashcard) {
            el.data.chapters.sort((a, b) => {
              return a.id - b.id;
            });
            const chaptersData = el.data.chapters.map((chItem, _) => {
              const chapterIds = chItem.id;
              const chapterName = chItem.title;

              if (isSimulation === false) {
                const _snakData = [...chItem.snacks];

                _snakData.sort((a, b) => {
                  return a.id - b.id;
                });
                const filteredSnackwithoutUnit = _snakData.filter(
                  (item) => item.chapterId === chapterIds
                );
                if (chItem.snacks) {
                  return {
                    type: 'chapter',
                    layer: 1,
                    name: chapterName,
                    id: chapterIds,
                    icon: <IconBar />,
                    label: chapterName,
                    isExpanded:false,
                    childrenElements:
                      filteredSnackwithoutUnit.length === 0
                        ? null
                        : [
                            ...filteredSnackwithoutUnit.map((snackItem, _) => {
                              const snackName = snackItem.title;
                              const snackId = snackItem.id;
                              return {
                                type: 'snack',
                                layer: 2,
                                id: snackId,
                                name: snackName,
                                icon: <IconBar />,
                                label: snackName,
                                isExpanded:false
                              };
                            }),
                          ],
                  };
                }
              } else {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  label: chapterName,
                  isExpanded:false
                };
              }
            });
            setStructure(chaptersData);
            setIsListDataLoading(false);
          } else {
            el.data[0].chapters.sort((a, b) => {
              return a.id - b.id;
            });
            const chaptersData = el.data[0].chapters.map((chItem, _) => {
              const chapterIds = chItem.id;
              const chapterName = chItem.title;

              if (isSimulation === false) {
                const _snakData = [...chItem.snacks];

                _snakData.sort((a, b) => {
                  return a.id - b.id;
                });
                const filteredSnackwithoutUnit = _snakData.filter(
                  (item) => item.chapterId === chapterIds
                );
                if (chItem.snacks) {
                  return {
                    type: 'chapter',
                    layer: 1,
                    name: chapterName,
                    id: chapterIds,
                    icon: <IconBar />,
                    label: chapterName,
                    isExpanded:false,
                    childrenElements:
                      filteredSnackwithoutUnit.length === 0
                        ? null
                        : [
                            ...filteredSnackwithoutUnit.map((snackItem, _) => {
                              const snackName = snackItem.title;
                              const snackId = snackItem.id;
                              return {
                                type: 'snack',
                                layer: 2,
                                id: snackId,
                                name: snackName,
                                icon: <IconBar />,
                                label: snackName,
                                isExpanded:false,

                              };
                            }),
                          ],
                  };
                }
              } else {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  label: chapterName,
                };
              }
            });
            setStructure(chaptersData);
            setIsListDataLoading(false);
          }
        }
      }
    }
  };
  const getListData = async () => {
    let token = localStorage.getItem('auth');
    getMenufromDB(token)
    .then((el) => {
      if (el.error) {
        return;
      }
      makeList({ data: el });
      dispatch(courseActions.addCourse(el));
    })
    .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    setIsListDataLoading(true);
  }, []);

  React.useEffect(() => {
    if (hierarchyData) {
      if (tab === "notes" ) { 
        const savedNotesData = JSON.parse(localStorage.getItem('notesGlobalState')) || {};
        if(savedNotesData?.data?.length > 0){
          setStructure(savedNotesData?.data)
          setIsListDataLoading(false);
          onListClick(savedNotesData?.selection,true)
        } 
        else{
          makeList(hierarchyData);
        }
      }
      else if (tab === "flashcards") {
        const savedFlashcardsData = JSON.parse(localStorage.getItem('flashcardsGlobalState')) || {};
        if (savedFlashcardsData?.data?.length > 0) {
          setStructure(savedFlashcardsData?.data);
          setIsListDataLoading(false);
          onListClick(savedFlashcardsData?.selection,true);
        } else {
          makeList(hierarchyData);
        }
      }
      else if(tab ==='assessment'){
        const savedAssessmentcardsData = JSON.parse(localStorage.getItem('assessmentGlobalState')) || {};
        if (savedAssessmentcardsData?.data?.length > 0) {
          setStructure(savedAssessmentcardsData?.data);
          setIsListDataLoading(false);
          onListClick(savedAssessmentcardsData?.selection,true);
        } else {
          makeList(hierarchyData);
        }
      }
      else if (tab === "analyticscards") {
        const savedAnalyticscardsData = JSON.parse(localStorage.getItem('analyticscardsGlobalState')) || {};
        if (savedAnalyticscardsData?.data?.length > 0) {
          setStructure(savedAnalyticscardsData?.data);
          setIsListDataLoading(false);
          onListClick(savedAnalyticscardsData?.selection,true);
        }
        else {
          makeList(hierarchyData);
        }
      }
      else if (tab === "simulationscards") {
        const savedSimultionsData = JSON.parse(localStorage.getItem('simulationscardsGlobalState')) || {};
        if (savedSimultionsData?.data?.length > 0) {
          setStructure(savedSimultionsData?.data);
          setIsListDataLoading(false);
          onListClick(savedSimultionsData?.selection,true);
        } else {
          makeList(hierarchyData);
        }
      }
      else{
        makeList(hierarchyData);
      }
    }
  }, [hierarchyData,tab]);
  
  React.useEffect(() => {
    if (getMenuStats === true) {
      setIsListDataLoading(true);
      getListData();
    }
  }, [getMenuStats]);

  return (
    <>
      <ErrorBoundary>
        {isListDataLoading ? (
          <Loader />
        ) : (
          <>
            {listDataErr ? (
              <ErrorMessage style={{ padding: '2rem 0.4rem' }}>
                Error, unable to retrieve data
              </ErrorMessage>
            ) : ( 
              <List
                setStructure={setStructure}
                structure = {structure}
                open={open}
                setOpen={toggleOpen}
                selectedData={selectedData}
                selection={selectedFromList}
                listSkeleton={structure}
                onItemClick={(e,i) => onListClick(e,i)}
                isFlashcard={isFlashcard}
                isLibrary={isLibrary}
                active={active}
                handleOpenMobileDropDown={handleOpenMobileDropDown}
                isAssesment={isAssesment}
                activeScreen={activeScreen}
                setActiveScreen={setActiveScreen}
                setSelectedData={setSelectedData}
                isNotes={isNotes}
              />
            )}
          </>
        )}
      </ErrorBoundary>
    </>
  );
};

export default LeftSide;

LeftSide.defaultProps = {
  getList: (e) => {
    return;
  },
  getCourseInfo: (e) => {
    return;
  },
};
