import React from "react";
import TypographyCompo from "../../../../../../UI/Typography/TypographyCompo";
import NotesContent from "./NotesContent";

const NotesChapterLoop = ({
  id,
  name,
  notes,
  filteredTags,
  selectedTag,
  selectedFromList,
  addToLibraryHandler,
  ...props
}) => {
  return (
    <React.Fragment>
      {notes.length > 0 ? (
        <>
          {notes.map((note) => {
            return (
              <NotesContent
                chapterId={id}
                addToLibraryHandler={(payload, selectedCategory) => {
                  addToLibraryHandler({
                    origin: payload,
                    selectedCategory,
                    content: note,
                    tag: selectedTag,
                  });
                  return true;
                }}
                tags={filteredTags}
                data={note.notes}
                title={note.topic}
                selection={selectedFromList}
                tagSelection={selectedTag}
                name={name}
                isInLibrary={note.combination.isInLibrary}
              />
            );
          })}
        </>
      ) : null}
    </React.Fragment>
  );
};

export default NotesChapterLoop;
