import PropTypes from "prop-types";
import React from "react";

function MoveLibrarySvg({ color }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          d="M16 4.58875C15.9999 4.09151 15.8023 3.61467 15.4506 3.26313L13.7369 1.54937C13.3853 1.19772 12.9085 1.00011 12.4113 1H2.875C2.37772 1 1.90081 1.19754 1.54917 1.54917C1.19754 1.90081 1 2.37772 1 2.875V14.125C1 14.6223 1.19754 15.0992 1.54917 15.4508C1.90081 15.8025 2.37772 16 2.875 16H14.125C14.6223 16 15.0992 15.8025 15.4508 15.4508C15.8025 15.0992 16 14.6223 16 14.125V4.58875Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.375 1V4.75H4.75V1M4.75 16V8.5H12.25V16"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

MoveLibrarySvg.propTypes = {
  color: PropTypes.any,
};

MoveLibrarySvg.defaultProps = {
  color: "white",
};

export default MoveLibrarySvg;
