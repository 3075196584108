import React, { useRef, useState, useEffect, useMemo } from 'react';
import LeftMenu from '../../../../LeftSideBar/LeftSide';
import BreadCrumb from '../../../../BreadCrumb';
import NotesHeader from '../../../../Headers/LearningResources/Notes/Notes';
import Grid from '../../../../../../UI/Grid/Grid';
import Card from '../../../../../../UI/Card/Card';
import { useSnackbar } from 'notistack';
import api from './../../../../../../../Services';
import capitalize from '../../../../../../../helpers/capitalize';
import Loader from '../../../../../../UI/Loader/Loader';
import isEmptyObj from '../../../../../../../helpers/objectIsEmpty';
import Alert from './../../../../../../UI/Alert/Alert';
import ErrorBoundary from './../../../../../../../Errors/ErrorBoundary';
import { useSelector } from 'react-redux';
import TypographyCompo from '../../../../../../UI/Typography/TypographyCompo';
import { groupBy } from 'lodash';
import NotesChapterLoop from './NotesChapterLoop';
import { useParams } from 'react-router-dom';
import { NotesContext } from '../../../../../../../contexts';
import { Box, Container } from '@mui/material';
import MobileDetector from '../../../../../../../helpers/mobileDetector';
import MobileBottomBar from '../../../../../../mobileBottomBar/MobileBottomBar';
import MobileCourseIndicator from '../../../../../../mobileBottomBar/MobileCourseIndicator';
import LogoLoader from '../../../../../../UI/Loader/logoLoader';
import useEstimatedCompletionTime from '../../../../hooks/calulateEstimatedCompletionTime';
import CourseInfo from '../../../../../../coursoInfo/courseInfo';
const Notes = () => {
  const isMobile = MobileDetector();
  const { enqueueSnackbar } = useSnackbar();
  const { id: courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const course = useSelector((state) => state.course);
  const searchResultsListRef = useRef();
  const [allTags, setAllTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectedFromList, setSelectedFromList] = useState({});
  const [selectedTag, setSelectedTag] = useState({ title: 'all' });
  const [hierarchyData, setHeirarchyData] = useState(null);
  const [showData, setShowData] = useState(true);
  const [openMobileDropDown, SetOpenMobileDropDown] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [activeItem, setActiveItem] = useState('');
  const [fetchStartTime, setFetchStartTime] = React.useState(null);
  const [fetchEndTime, setFetchEndTime] = React.useState(null);
  const estimatedCompletionTime = useEstimatedCompletionTime(
    fetchStartTime,
    3000
  );
  

  const [structuredData , setStructuredData] = useState([])

  const [dataCorrespondingToSelection, setDataCorrespondingToSelection] =
    useState([]);

  const getNotesFromApi = async (id, type) => {
    try {
      setFetchStartTime(Date.now());
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            let token = localStorage.getItem('auth');
            let temp = await api.getNotesFromDbLearningResources(
              { id, type },
              token
            );
            setFetchEndTime(Date.now());
            resolve(temp); // Resolve the promise with the fetched data
          } catch (error) {
            reject(error); // Reject the promise if an error occurs
          }
        }, 3000);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOpenMobileDropDown = () => {
    SetOpenMobileDropDown(!openMobileDropDown);
  };
  const handleDataIfTrue = (payload, userSelection) => {
    const generateMasterArr = (Arr) => {
      const arrGenerated = Arr.map((arrEl) => {
        let topic = '';
        if (arrEl.snackId) {
          // find the snack in course with the snack id from arrEl
          topic = payload.data.rows.find(
            (snack) => snack.snackId === arrEl.snackId
          )?.snack?.title;
        } else {
          // find the chapter in course with the chapter id id from arrEl
          topic = payload.data.rows.find(
            (chap) => chap.chapterId === arrEl.chapterId
          )?.chapter?.title;
        }
        const notes = rows.filter(
          (el) =>
            el.chapterId === arrEl.chapterId && el.snackId === arrEl.snackId
        );
        return {
          topic,
          notes,
          combination: arrEl,
        };
      });

      return arrGenerated;
    };
    setSelectedFromList(userSelection);
    if (payload.data.count === 0) {
      return setDataCorrespondingToSelection([]);
    }

    let keys = []; //Array of {chapterId:id, snackId:id}

    let rows = payload.data.rows;

    rows.forEach((el) => {
      const testObj = {
        chapterId: el.chapterId,
        snackId: el.snackId,
        isInLibrary: el.isInLibrary,
      };
      const elementIsInKey = keys.find(
        (eachKey) =>
          eachKey.chapterId === el.chapterId && eachKey.snackId === el.snackId
      );
      if (!elementIsInKey) {
        keys.push(testObj);
      }
    });
    const chapterWiseGroups = groupBy(keys, 'chapterId'); // group by chapter ids e.g { chapterId: [{chapterId:id, snackId:id}]}
    let chaptersArr = [];

    for (let i in chapterWiseGroups) {
      const chapterId = +i;
      const notesGroupedAsTags = generateMasterArr(chapterWiseGroups[i]);
      const chapterInfo = {
        id: chapterId,
        name: capitalize(
          payload.data.rows.find((chap) => chap.chapterId === chapterId)
            ?.chapter?.title
        ),
        notes: notesGroupedAsTags,
      };

      chaptersArr.push(chapterInfo);
    }

    setDataCorrespondingToSelection(chaptersArr);
    return true;
  };

  const getContentHandler = (id, type, selection) => {
    setLoading(true);
    getNotesFromApi(id, type)
      .then((el) => {
        if (el.error) {
          console.error(el);
          setLoading(false);
          throw Error('Network Response Error');
        }
        setLoading(false);
        //set data to be view
        handleDataIfTrue(el, selection);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        throw Error('Network Request Error');
      });
  };

  const handleExpansion = (item,structure) => {
    switch(item.type) {
      case 'unit':
          const updatedData = structure.map((unit) => {
            if (unit.id === item.id) {
              return { ...unit, isExpanded: !unit.isExpanded , nested : unit.childrenElements   };
            }
            return {...unit,isExpanded:false, nested : unit.childrenElements};
          });
          return updatedData;
        break;

      case 'chapter':  
      if(item.unitId){
          let updatedData = structure.map((unit) => {
            if (unit.id === item.unitId) {
              const modifiedChildren = unit.childrenElements.map((element)=>{
                   if(element.id === item.id){
                    return { ...element, isExpanded: !element.isExpanded, nested : element.childrenElements };
                   }
                   return {...element, isExpanded :false,  nested : element.childrenElements}  
              })
              return {...unit,childrenElements:modifiedChildren ,  nested :modifiedChildren}
            }
          
            return unit;
          });
          return updatedData;
      }
      else{
          let updatedData = structure.map((chapter) => {
            if (chapter.id === item.id) {
              return { ...chapter, isExpanded: !chapter.isExpanded ,nested : chapter.childrenElements };
            }
            return {...chapter,isExpanded:false,nested : chapter.childrenElements};
          });
          return updatedData;
      }
        break;
        
        case "snack":
            const updatedDataSnack = structure.map((unit) => {  
              if (unit.id === item.unitId) {
                let modifiedUnitsChildren= unit.childrenElements.map((element)=>{
                     if(element.id === item.chapterId){
                     let modifiedSnacksChildren =element.childrenElements.map((snackElements)=>{
                          if(snackElements.id ===item.id){
                            return { ...snackElements, isExpanded: !snackElements.isExpanded, nested : snackElements.childrenElements };
                          }
                          return snackElements;   
                      })
                      return {...element,childrenElements:modifiedSnacksChildren , nested : modifiedSnacksChildren};   

                    }
                     return element  
                })
                return {...unit,childrenElements:modifiedUnitsChildren, nested : modifiedUnitsChildren};   
              }
            
              return unit;
            });
            return updatedDataSnack;
      default:
        console.log("Unknown item type");
    }
  
  };

  const  handleSelectedData  = (e) => {

    if (e.type === 'unit') {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: 'unit',
        active: 'unit',
      });
    } else if (e.type === 'chapter') {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        chaptername: e.name,
        type: 'chapter',
        active: 'chapter',
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        snackname: e.name,
        type: 'snack',
        active: 'snack',
      }));
    }

  }
  function handleSelection(e) {
    const notes = handleExpansion(e,structuredData)
    const notesGlobal = {
      data: notes, 
      tab:"notes",
      selection : e
    };
    localStorage.setItem('notesGlobalState', JSON.stringify(notesGlobal));
    handleSelectedData(e)
  }

  // will make call for selected item unit, chapter or snack
  const selectFromListHandler = async (e, isStorage = false) => {
    if(isStorage){
      handleSelectedData(e)
      setActiveItem(e.label);
      setDataCorrespondingToSelection([]);
      setShowData(false);
      const { id, type } = e;
      if (e.nested && !e.isExpanded) {
        if(e.layer === 2){
          getContentHandler(e.unitId,"unit",e)
        }
        // it has some chapter or snacks and is already expanded then no api call will be made
        return;
      }
  
      getContentHandler(id, type, e);
    }
    else{
    setActiveItem(e.label);
    handleSelection(e);
    setDataCorrespondingToSelection([]);
    setShowData(false);
    const { id, type } = e;
    if (e.nested && !e.isExpanded) {
      if(e.layer === 2){
        getContentHandler(e.unitId,"unit",e)
      }
      // it has some chapter or snacks and is already expanded then no api call will be made
      return;
    }

    getContentHandler(id, type, e);
  }
  };

  const handleTagSelection = (_, tag) => {
    setSelectedTag(tag);
  };

  const toggleTagsHandler = React.useCallback(
    (selection) => {
      if (!selection.id && selection.title === 'all') {
        setFilteredTags([...allTags]);
      } else {
        let temp = allTags.filter((each) => each.id === selection.id);
        setFilteredTags(temp);
      }
      return true;
    },
    [allTags]
  );

  const fetchNotesHeirarchy = async () => {
    let token = localStorage.getItem('auth');
    await api
      .getHierarchyForNotes(token, courseId)
      .then((resp) => setHeirarchyData(resp))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    toggleTagsHandler(selectedTag);
  }, [selectedTag, toggleTagsHandler]);


  useEffect(() => {
    fetchNotesHeirarchy();
  }, []);
  
  
  const addNoteToLibraryApiCall = async (payload) => {
    let token = localStorage.getItem('auth');
    let temp = api.addNoteToLibrary(payload, token);
    return temp;
  };

  const pushFullContentToLibrary = (payload) => {
    let data = {};
    const { chapterId, snackId } = payload;

    data.chapterId = chapterId;
    if (snackId) {
      data.snackId = snackId;
    }
    if (data.snackId || data.chapterId) {
      addNoteToLibraryApiCall(data)
        .then((el) => {
          handleResponseOfLibraryPush(el);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleResponseOfLibraryPush = (res) => {
    if (res.ok) {
      return true;
    } else
      enqueueSnackbar(`Failed to add to library!`, {
        variant: 'error',
      });
    return false;
  };

  const pushTagToLibrary = (payload) => {
    const { chapterId, snackId, tagId } = payload;
    addNoteToLibraryApiCall({ chapterId, snackId, tagId })
      .then((el) => {
        handleResponseOfLibraryPush(el);
      })
      .catch((err) => console.error(err));
  };

  const addToLibraryHandler = (payload) => {
    const { content, selectedCategory } = payload;

    const { chapterId, snackId } = content.combination;

    let toSend = { type: 'chapter' };
    toSend.chapterId = chapterId;

    if (snackId) {
      toSend.snackId = snackId;
      toSend.type = 'snack';
    }
    switch (selectedCategory) {
      case 'specific-tag': {
        toSend.tagId = payload.origin.tagId;
        pushTagToLibrary(toSend);
        break;
      }
      case 'full-content': {
        pushFullContentToLibrary(toSend);
        break;
      }
      default: {
        break;
      }
    }
  };

  const generateUnitName = (id, type) => {
    switch (type) {
      case 'unit': {
        const getUnit = course.units.find((eachUnit) => eachUnit.id === id);
        return getUnit.title ? getUnit.title : null;
      }

      case 'chapter': {
        const unitId = course.chapters.find(
          (eachChap) => eachChap.id === id
        )?.unitId;
        if (unitId) {
          const getUnit = course.units.find(
            (eachUnit) => eachUnit.id === unitId
          );
          return getUnit.title ? getUnit.title : null;
        }
        return null;
      }

      case 'snack': {
        const getChapterId = course.snacks.find(
          (snack) => snack.id === id
        )?.chapterId;
        const getUnitId = course.chapters.find(
          (chap) => chap.id === getChapterId
        )?.unitId;
        const unitDetails = course.units.find((unit) => unit.id === getUnitId);
        return unitDetails ? unitDetails.title : null;
      }

      default: {
        return null;
      }
    }
  };

  useEffect(()=>{
    if(selectedData?.layer === 1 && selectedData?.isExpanded===false){
      setShowData(true)
    }

  },[selectedData])
  
  return (
    <Box>
      <Container sx={{ padding: { xs: '25px', sm: '0px' }, minWidth: '100%' }}>
        <BreadCrumb />
        <NotesHeader
          allTags={allTags}
          setAllTags={setAllTags}
          activeTag={selectedTag}
          handleSelection={handleTagSelection}
          ref={searchResultsListRef}
          tab="Notes"
        />

        {/* Mobile view */}
        {showData && (
          <MobileCourseIndicator
            courseId={courseId}
            tab="Notes"
            welcomeDetails="Please select a unit, chapter or snack to view notes."
          />
        )}
      </Container>
      <MobileBottomBar
        selectionText="Click here to get started "
        openMobileDropDown={openMobileDropDown}
        handleOpenMobileDropDown={handleOpenMobileDropDown}
        showData={showData}
        selectedData={selectedData}
        leftSideMenu={
          <LeftMenu
            selectedFromList={selectedFromList}
            onListClick={selectFromListHandler}
            hierarchyData={hierarchyData}
            active={activeItem}
            isSimulation={false}
            handleOpenMobileDropDown={handleOpenMobileDropDown}
            isNotes={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            setStructuredData={setStructuredData}
            tab={"notes"}
          />
        }
      />
      {isMobile ? (
        <Grid
          item
          sx={{ width: '100%', padding: '0px 35px', paddingBottom: '100px' }}
        >
          {loading ? (
            <>
              {!fetchEndTime && estimatedCompletionTime ? (
                <LogoLoader time={estimatedCompletionTime} rep={'infinite'} />
              ) : (
                ''
              )}
            </>
          ) : (
            <ErrorBoundary>
              {showData ? (
                ''
              ) : (
                // <Alert
                //   severe="info"
                //   message="Click on unit or chapter to show the Notes"
                // />
                <NotesContext.Provider
                  value={{
                    dataCorrespondingToSelection,
                    setDataCorrespondingToSelection,
                  }}
                >
                  {isEmptyObj(selectedFromList) ? null : !loading ? (
                    dataCorrespondingToSelection.length > 0 ? (
                      <Card nopadding={true}>
                        {course.haveUnits ? (
                          <>
                            <TypographyCompo
                              style={{ padding: '2rem 1rem' }}
                              variant={'h4'}
                            >
                              {generateUnitName(
                                selectedFromList.id,
                                selectedFromList.type
                              )}
                            </TypographyCompo>
                          </>
                        ) : null}

                        {dataCorrespondingToSelection.map((data) => {
                          return (
                            <NotesChapterLoop
                              selectedTag={selectedTag}
                              selectedFromList={selectedFromList}
                              filteredTags={filteredTags}
                              addToLibraryHandler={addToLibraryHandler}
                              {...data}
                            />
                          );
                        })}
                      </Card>
                    ) : (
                      <Alert
                        message={'Make selection to view notes!'}
                        severity={'info'}
                      />
                    )
                  ) : (
                    <Loader />
                  )}
                </NotesContext.Provider>
              )}
            </ErrorBoundary>
          )}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={3} sm={12} sx={{ width: '100%' }}>
            <ErrorBoundary>
              <Card nopadding={true}>
                <LeftMenu
                  selectedFromList={selectedFromList}
                  onListClick={selectFromListHandler}
                  hierarchyData={hierarchyData}
                  isNotes={true}
                  isSimulation={false}
                  selectedData={selectedData}
                  setStructuredData={setStructuredData}
                  tab={"notes"}
                  setSelectedData={setSelectedData}
                />
              </Card>
            </ErrorBoundary>
          </Grid>
          <Grid item md={9} sm={12} sx={{ width: '100%' }}>
            {loading ? (
              <>
                {!fetchEndTime && estimatedCompletionTime ? (
                  <LogoLoader time={estimatedCompletionTime} rep={'infinite'} />
                ) : (
                  <Loader />
                )}
              </>
            ) : (
              <ErrorBoundary>
                {showData ? (
                  <CourseInfo courseId={courseId} tab={'Notes'} />
                ) : (
                  <NotesContext.Provider
                    value={{
                      dataCorrespondingToSelection,
                      setDataCorrespondingToSelection,
                    }}
                  >
                    {isEmptyObj(selectedFromList) ? null : !loading ? (
                      dataCorrespondingToSelection.length > 0 ? (
                        <Card nopadding={true}>
                          {course.haveUnits ? (
                            <>
                              <TypographyCompo
                                style={{ padding: '2rem 1rem' }}
                                variant={'h4'}
                              >
                                {generateUnitName(
                                  selectedFromList.id,
                                  selectedFromList.type
                                )}
                              </TypographyCompo>
                            </>
                          ) : null}

                          {dataCorrespondingToSelection.map((data) => {
                            return (
                              <NotesChapterLoop
                                selectedTag={selectedTag}
                                selectedFromList={selectedFromList}
                                filteredTags={filteredTags}
                                addToLibraryHandler={addToLibraryHandler}
                                {...data}
                              />
                            );
                          })}
                        </Card>
                      ) : (
                        <Alert
                          message={'Make selection to view notes!'}
                          severity={'info'}
                        />
                      )
                    ) : (
                      <Loader />
                    )}
                  </NotesContext.Provider>
                )}
              </ErrorBoundary>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Notes;
