import React from 'react'

export default function ExpandMobileIcon({ expanded }) {
  return (<>
    {

      expanded ?
        <div style={{position:'absolute' ,top:'-12px', left:'-20px' }}> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="2" viewBox="0 0 15 2" fill="none">
          <path d="M14.1667 1.83177H0.83332C0.372715 1.83177 0 1.4588 0 0.998792C0 0.538783 0.372715 0.16581 0.83332 0.16581H14.1666C14.6273 0.16581 15 0.538783 15 0.998792C15 1.4588 14.6273 1.83177 14.1667 1.83177Z" fill="#E015A2" />
        </svg> </div> :  <div style={{ position:'absolute' ,top:'-15px' }}>  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
          <path d="M7.49714 15.5C7.03653 15.5 6.66382 15.1269 6.66382 14.6667V1.33333C6.66382 0.873131 7.03653 0.5 7.49714 0.5C7.95774 0.5 8.33046 0.873131 8.33046 1.33333V14.6667C8.33046 15.1269 7.95774 15.5 7.49714 15.5Z" fill="#E015A2" />
          <path d="M14.1667 8.83177H0.83332C0.372715 8.83177 0 8.4588 0 7.99879C0 7.53878 0.372715 7.16581 0.83332 7.16581H14.1666C14.6273 7.16581 15 7.53878 15 7.99879C15 8.4588 14.6273 8.83177 14.1667 8.83177Z" fill="#E015A2" />
        </svg>

        </div>}

  </>

  )
}
