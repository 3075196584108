import { styled } from '@mui/system';
import palette from '../../../config/palette';
import TextField from '@mui/material/TextField';

export const SearchField = styled(TextField)(({ theme }) => ({
  width: '100%',
  borderBottom: '1px solid #ADB4C5',

  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

export const SearchBarWrapper = styled('div')(() => ({
  width: '100%',
}));
